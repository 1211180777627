import httpRequests from "./httpRequestHandler";
import * as commonConstants from "../constants/constants.js";
import digiSignConstants from '../constants/digitalsignature/constants';

const API_URL = digiSignConstants.DIGI_SIGN_URL;


export const fetchDigiSignResult = (searchInfo) => {

    let fromDate = searchInfo.searchInfo.fromDate;
    let toDate = searchInfo.searchInfo.toDate;
    let recordsType = Number(searchInfo.searchInfo.recordsType);
    let offset = searchInfo.searchInfo.offset;
    let memberId = searchInfo.searchInfo.memberId;
    let clubId = searchInfo.searchInfo.clubId;
    let recordsTypeParam;
    let queryString = '';

    switch (recordsType) {
        case commonConstants.SUCCESS_RECORDS_TYPE:
            recordsTypeParam = 'success';
            break;
        case commonConstants.FAILURE_RECORDS_TYPE:
            recordsTypeParam = 'failure';
            break;
        case commonConstants.INPROCESS_RECORDS_TYPE:
            recordsTypeParam = 'inprocess';
            break;
        default:
            recordsTypeParam = 'success';
    }

    queryString = queryString + `fromDate=${fromDate}`;
    queryString = queryString + `&toDate=${toDate}`;
    queryString = queryString + `&recordsType=${recordsTypeParam}`;
    queryString = queryString + `&offset=${offset}`;

    if (memberId !== '') {

        queryString = queryString + `&memberId=${memberId}`;
    }
    if (clubId !== '') {

        queryString = queryString + `&clubId=${clubId}`;
    }

    let url = process.env.REACT_APP_API_URL + API_URL.FETCH_DIGISIGN_RESULT + '/?' + queryString;

    return httpRequests.get(url);
}


export const fetchDigiSignTemplates = () => {

    let url = process.env.REACT_APP_API_URL + API_URL.FETCH_DIGISIGN_TEMPLATES;

    return httpRequests.get(url);
}

export const fetchDigiSignFiles = (date) => {
    let url = process.env.REACT_APP_API_URL + API_URL.FETCH_DIGISIGN_FILES + `?date=${date}`;

    return httpRequests.get(url);
}


export const sendDigiSignEmails = (records) => {
    let url = process.env.REACT_APP_API_URL + API_URL.SEND_DIGISIGN_EMAILS;
    return httpRequests.post(url, { ids: records });
}

export const exportDigiSignResultCsv = (searchInfo) => {

    let fromDate = searchInfo.searchInfo.fromDate;
    let toDate = searchInfo.searchInfo.toDate;
    let recordsType = Number(searchInfo.searchInfo.recordsType);
    let memberId = searchInfo.searchInfo.memberId;
    let clubId = searchInfo.searchInfo.clubId;
    let filename = '';
    let recordsTypeParam;
    let queryString = '';

    switch (recordsType) {
        case commonConstants.SUCCESS_RECORDS_TYPE:
            recordsTypeParam = 'success';
            filename = 'Success_Agreements';
            break;
        case commonConstants.FAILURE_RECORDS_TYPE:
            recordsTypeParam = 'failure';
            filename = 'Failed_Agreements'
            break;
        case commonConstants.INPROCESS_RECORDS_TYPE:
            recordsTypeParam = 'inprocess';
            filename = 'Inprocess_Agreements';
            break;
        default:
            recordsTypeParam = 'success';
            filename = 'Success_Agreements';
    }

    queryString = queryString + `fromDate=${fromDate}`;
    queryString = queryString + `&toDate=${toDate}`;
    queryString = queryString + `&recordsType=${recordsTypeParam}`;

    if (memberId !== '') {
        queryString = queryString + `&memberId=${memberId}`;
        filename = filename + '_' + memberId;
    }
    if (clubId !== '') {
        queryString = queryString + `&clubId=${clubId}`;
        filename = filename + '_' + clubId;
    }

    filename = toDate === fromDate ? filename + '_' + toDate : filename + '_' + fromDate + '_To_' + toDate;

    let url = process.env.REACT_APP_API_URL + API_URL.EXPORT_DIGISIGN_RESULT + '/?' + queryString;
    
    return httpRequests.fileDownload(url, filename);
}

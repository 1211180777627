import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import HeaderDS from '../../common/headers/headerDS';
import EmailTemplatesTable from './emailTemplatesTable'
import digiSignConstants from '../../../constants/digitalsignature/constants'
class DigiSignTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allTemplates: [],
            isLoading: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isLoading: false
        })

        this.setState({
            allTemplates: nextProps.templates.digiSignTemplates || []
        });

    }

    componentWillMount() {
        this.props.checkIsLoggedIn();
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.props.getAllEmailTemplates();
    }

    render() {
        return (
            <div className="layout-container">
                <HeaderDS logout={this.props.logout} />
                <main className="container">
                    {this.state.isLoading}
                    <h1 className="page-heading">Digital Signature Templates</h1>
                    <div className="file-upload-list white-bg box-shadow">
                        <EmailTemplatesTable
                            isLoading={this.state.isLoading}
                            templates={this.state.allTemplates}
                        />
                        <div className="message-info">
                            {digiSignConstants.EMAIL_TEMPLATES_MESSAGE}
                        </div>
                    </div>
                </main>

                <ToastContainer autoClose={2000} />
            </div>
        );
    }

}

export default DigiSignTemplate;
import React from 'react';
import * as commonConstants from '../../../../constants/constants';
import SentEmailResultTable from './successresultstable';
import InProgressResultTable from './inprocessresulttable';
import ErrorResultTable from './errorresulttable';


class DigiSignResultTable extends React.Component {

    render() {
        return (
            <React.Fragment>
                {Number(this.props.recordsType) === commonConstants.SUCCESS_RECORDS_TYPE
                    ? (<SentEmailResultTable
                        isLoading={this.props.isLoading}
                        selectedDate={this.props.selectedDate}
                        currentDate={this.props.currentDate}
                        records={this.props.records}
                        currentPaginateNumber={this.props.currentPaginateNumber}
                        handlePagination={this.props.handlePagination}
                        isSearchCriteria={this.props.isSearchCriteria}
                        handleCheck={this.props.handleCheck}
                        checkedRecords= {this.props.checkedRecords}
                        handleCheckAll={this.props.handleCheckAll}
                        memberId={this.props.memberId}/>)
                    : (Number(this.props.recordsType) === commonConstants.FAILURE_RECORDS_TYPE
                        ? <ErrorResultTable
                                isLoading={this.props.isLoading}
                                selectedDate={this.props.selectedDate}
                                currentDate={this.props.currentDate}
                                records={this.props.records}
                                currentPaginateNumber={this.props.currentPaginateNumber}
                                handlePagination={this.props.handlePagination}
                                isSearchCriteria={this.props.isSearchCriteria}
                                memberId={this.props.memberId}/>
                        : (Number(this.props.recordsType) === commonConstants.INPROCESS_RECORDS_TYPE
                            ? <InProgressResultTable
                                    isLoading={this.props.isLoading}
                                    selectedDate={this.props.selectedDate}
                                    currentDate={this.props.currentDate}
                                    records={this.props.records}
                                    currentPaginateNumber={this.props.currentPaginateNumber}
                                    handlePagination={this.props.handlePagination}
                                    isSearchCriteria={this.props.isSearchCriteria}
                                    memberId={this.props.memberId}/>
                            : ''))}

            </React.Fragment>

        );
    }
}

export default DigiSignResultTable;

import React from 'react';
import Loader from 'react-loader-advanced';
import { LOADER, REFRESH_IMAGE } from '../../../constants/constants';
import '../../../static/css/App.css';
import '../../../static/css/index.css';
import '../../../static/css/agreementCancellationResults.css';
import * as commonConstants from '../../../constants/constants';

class EmailTemplatesTable extends React.Component {

    render() {
        let componentData;
        let templatesArray = [];

        this.props.templates.map((template, index) => {
            template = <tr key={index}>
                <td>{template.AgreementGroup?template.AgreementGroup:'Any other'}</td>
                <td>{template.AgreementGroupId?template.AgreementGroupId:'N/A'}</td>
                <td>{template.TemplateName}</td>
                <td>{template.HubspotTemplateId}</td>
            </tr>
            return templatesArray.push(template);
        });
        componentData = 
            <div className="table-responsive">
                {this.props.templates.length === 0
                    ? <h3>{commonConstants.NO_RECORDS_MESSAGE}</h3>
                    : <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Agreement Group</th>
                                <th>Agreement Group Id</th>
                                <th>Template Group</th>
                                <th>HubSpot Template Id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                templatesArray
                            }
                        </tbody>
                    </table>
                }
                
            </div>
        return (
            <React.Fragment>
                {!this.props.isLoading && componentData}
            </React.Fragment>
        );
    }
}
export default EmailTemplatesTable;
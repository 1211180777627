import { FileStatusEnum,DigiSignFileStatusEnum } from '../constants/constants';
import { toast } from 'react-toastify';

export const getFileStatusName = (fileStatusValue) => {
    const fileStatusName = FileStatusEnum.properties[fileStatusValue].name;
    return fileStatusName;
}

export const getFileStatusNameDigiSign = (fileStatusValue) => {
    
    const fileStatusName = DigiSignFileStatusEnum.properties[fileStatusValue].name;
    return fileStatusName;
}

/**
     *
     * @param {Date} dateObject
     * getting the date 45 before the current
     */
export const get45DaysBeforeDate = (dateObject) => {
    let beforeDays = 45;
    dateObject.setDate(dateObject.getDate() - beforeDays);
    return dateObject
        .toISOString()
        .substring(0, 10);
}

//getting date formatted as accepted by the date picker
//write the format expected
export const getCurrentDate = () => {

    let dateObj = new Date();

    let year = dateObj.getFullYear();
    let month = dateObj.getMonth();
    let date = dateObj.getDate();

    let formattedMonth = (month + 1) < 10
        ? '0' + (month + 1)
        : (month + 1);
    let formattedDate = date < 10
        ? '0' + date
        : date;

    let currentDate = year + '-' + formattedMonth + '-' + formattedDate;

    return currentDate;
}

export const notifyErrorMessage = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_CENTER
    });

}
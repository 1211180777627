export const FETCH_INVOICE_FILES = 'FETCH_INVOICE_FILES';
export const FETCH_INVOICE_FILES_SUCCESS = 'FETCH_INVOICE_FILES_SUCCESS';
export const FETCH_INVOICE_FILES_FAILURE = 'FETCH_INVOICE_FILES_FAILURE';


export const FETCH_INVOICE_RESULT = 'FETCH_INVOICE_RESULT';
export const FETCH_INVOICE_RESULT_SUCCESS = 'FETCH_INVOICE_RESULT_SUCCESS';
export const FETCH_INVOICE_RESULT_FAILURE = 'FETCH_INVOICE_RESULT_FAILURE';

export const EXPORT_INVOICE_RESULT_CSV = 'EXPORT_INVOICE_RESULT_CSV';
export const EXPORT_INVOICE_RESULT_CSV_SUCCESS = 'EXPORT_INVOICE_RESULT_CSV_SUCCESS';
export const EXPORT_INVOICE_RESULT_CSV_FAILURE = 'EXPORT_INVOICE_RESULT_CSV_FAILURE';


import React from 'react';
import { Redirect } from 'react-router-dom';
import {LOADER,KEY} from '../../constants/constants';
import '../../static/css/App.css';
import '../../static/css/login.css';
import Header from '../common/headers/header';
const encryptor = require('simple-encryptor')(KEY);

export class Login extends React.Component{  

    constructor(props) {
        super(props);

        let username = '';
        let password = '';

        if(localStorage.getItem('username')){
          let decryptedUsername = encryptor.decrypt(localStorage.getItem('username'));
          username = decryptedUsername || '';
        }
        if(localStorage.getItem('password')){
          let decryptedPassword = encryptor.decrypt(localStorage.getItem('password'));
          password = decryptedPassword || '';
        }

        this.state = {
          isLoading:false,
          username: username,
          password: password      
      };
    }


    componentWillMount() {
      this.props.checkIsLoggedIn();
    }
       
    componentWillReceiveProps(nextProps){
      this.setState({
        isLoading:false
      });      
    }

    handleReset = (event)=>{

      event.preventDefault();
      
      this.setState({
        username: '',
        password: '',
        isLoading: false        
      });       

    };
     

    handleInputChange = (event)=> {

      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value; //for remember me field
      const name = target.name;
      
      if(target.type === 'checkbox'){
        this.props.updateRememberMe(value);
      }else{
        this.setState({
          [name]: value
        });
      }
        
      };
      
      handleLogin = (event)=>{

      //spinner loader starts
      this.setState({
        isLoading:true
      });     

        let user ={
          'username':this.state.username,
          'password':this.state.password
        };

        this.props.login(user,this.props.rememberMe);
        event.preventDefault();        
      }
      
    render(){

      if(this.props.isLoggedIn==='')
        {
            return <div>{LOADER}</div>
            
        }
      if (this.props.isLoggedIn) {
        return <Redirect to='/home' />;
    }
      let usernameMessage,passwordMessage;
      let serverErrorMessage;

      
          if(this.props.loginError.status){
            if(this.props.loginError.usernameError){
              usernameMessage = <div  className="help-block with-errors">{this.props.loginError.usernameErrorMessage}</div>;
            }
            if(this.props.loginError.passwordError){
              passwordMessage = <div  className="help-block with-errors">{this.props.loginError.passwordErrorMessage}</div>;
            }
            if(!this.props.loginError.verifyLoginError && !this.props.loginError.usernameError && !this.props.loginError.passwordError ){
              serverErrorMessage = <div className="login-server-error">! Invalid Username or Password !</div>
            }
           
          }
        return (
        <React.Fragment>
          <div className="layout-container">
              <Header/>            
              <main className="container">
            
              {serverErrorMessage} 
              <div className="login-form box-shadow">   
                      
              
              <h2>Account Login</h2>  
              {this.state.isLoading && LOADER}
                
                <form className="loginForm">
              
                <div className="form-group">
                  
                  <input className="form-control " type="text" name="username" placeholder="Enter Username" value={this.state.username} onChange={this.handleInputChange} />
                      {usernameMessage}
                </div>
                <div className="form-group">
                  
                  <input className="form-control " type="password" name="password" placeholder="Enter Password" value={this.state.password} onChange={this.handleInputChange}/>
                  {passwordMessage}
                </div>
                <div className="form-group">
                <input type="checkbox" id="rememberCheck" name="rememberMe" defaultChecked={this.props.rememberMe} onChange={this.handleInputChange} />
                <label htmlFor="rememberCheck">Remember me</label>
              </div>    
                <div className="form-group"> 
                  <input type="submit" className="form_submit_btn" onClick={this.handleLogin} value="Login" />    
                </div>    
            </form>
            </div>
          </main>
        </div>
      </React.Fragment>
      );
    }
}


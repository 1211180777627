import React from 'react';
import FileInput from './fileInput';
import * as constants from '../../constants/constants';
import HeaderAC from '../common/headers/headerAC';
import '../../static/css/fileUploadAgreementCancellation.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Loader from 'react-loader-advanced';
import { LOADER } from '../../constants/constants';


class FileUploadAgreementCancellation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: '',
            newFileName: '',
            newFileState: '',
            allFiles: [],
            isLoading: false,
            isLoadingFile: false
        };
        this.polling = '';
        this.fileName = '';
        this.filereader = '';
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.handleUploadToServer = this.handleUploadToServer.bind(this);
        this.checkValidFileName = this.checkValidFileName.bind(this);
        this.getCurrentDate = this.getCurrentDate.bind(this);
        this.getFormattedMonthForCalender = this.getFormattedMonthForCalender.bind(this);
        this.handleSelectedDate = this.handleSelectedDate.bind(this);
        this.getFormattedDateForCalender = this.getFormattedDateForCalender.bind(this);
        this.checkFileInDb = this.checkFileInDb.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            isLoading: false
        })
        if (nextProps.files.length !== this.state.allFiles.length) {
            this.setState({
                allFiles: nextProps.files
            });
        }

    }

    componentWillMount() {
        this.props.checkIsLoggedIn();
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.props.getAllFiles(this.getCurrentDate());
    }

    handleSelectedDate(date) {
        this.setState({
            isLoading: true
        });
        this.props.getAllFiles(date);
    }

    checkValidFileName(fileName) {
        if (fileName) {
            let regex = constants.REGEX_FILENAME;
            let isValid = regex.test(fileName.name);
            return isValid;
        }

    }

    getCurrentDate() {
        let dateObj = new Date();
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let date = dateObj.getDate();
        let formattedMonth = this.getFormattedMonthForCalender(month + 1);
        let formattedDate = this.getFormattedDateForCalender(date);

        let currentDate = year + '-' + formattedMonth + '-' + formattedDate;

        return currentDate;
    }

    getFormattedMonthForCalender(month) {
        if (month < 10) {
            return '0' + month;
        }
        return month;

    }

    getFormattedDateForCalender(date) {

        if (date < 10) {
            return '0' + date;
        }
        return date;
    }

    handleUpload() {
        let file = this.state.selectedFile.file;
        this.filereader = new FileReader();
        this.filereader.onloadend = () => this.handleFileRead();
        this.filereader.readAsText(file);
    }

    checkFileInDb() {
        let filename = this.state.selectedFile.filename;
        const data = new FormData();
        data.append('filename', filename);
        let token = localStorage.getItem('token');
        token = 'Token ' + token;
        let url = process.env.REACT_APP_API_URL + constants.CHECK_FILE_IN_DB;
        return axios.post(url, data, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                if (response.data === true) {
                    clearInterval(this.polling);//clearing the interval created
                    let files = this.state.allFiles;
                    if (files[0].status === 'Processed') {
                        files[0] = { CsvFileName: this.state.selectedFile.filename, status: 'Processed' };
                    }
                    else
                        files[0] = { CsvFileName: this.state.selectedFile.filename, status: 'In Process' };
                    this.setState({
                        allFiles: files,
                        isLoadingFile: false
                    });
                }
            }).catch(err => {
                console.log(err);
            });
    }

    handleFileRead() {
        let allFiles1 = this.props.files;
        allFiles1.unshift({ CsvFileName: this.state.selectedFile.filename, status: 'Uploading' });
        this.setState({
            newFileName: '',
            allFiles: allFiles1,
            isLoadingFile: true
        });
        this.polling = setInterval(() => this.checkFileInDb(), 1500);
        //setting new file stae to uploading
       this.handleUploadToServer();
    }

    handleUploadToServer() {
        // this.props.uploadData(this.state.selectedFile);

        let token = localStorage.getItem('token');
        token = 'Token ' + token;
        let file = this.state.selectedFile.file;
        let filename = this.state.selectedFile.filename;
        const data = new FormData();
        data.append('file', file);
        data.append('filename', filename);
        let url = process.env.REACT_APP_API_URL + constants.UPLOAD_DATA_AC_URL;
        return axios.post(url, data, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                let files = this.state.allFiles;
                files[0] = { CsvFileName: this.state.selectedFile.filename, status: 'Processed' };
                this.setState({
                    allFiles: files
                });
            }).catch(err => {
                console.log(err);
            });

    }

    notifyErrorInvalid = () => {
        toast.error(`Invalid file name. Please rename file as Cancel_YYYY-MM-DD_HH-MM`, {
            position: toast.POSITION.TOP_CENTER
        });
    }

    handleSelectedFile(fileSelected) {
        let selectedFile = fileSelected.currentTarget.files[0];
        let isValid = this.checkValidFileName(selectedFile);
        if (isValid) {
            let file = {
                file: selectedFile,
                filename: selectedFile.name,
                isUploading: false,
                isUploaded: false,
                errorInUploading: false,
                isProcessing: false,
                isProcessed: false,
                errorInProcessing: false,
            };
            this.setState({ selectedFile: file, newFileName: selectedFile.name });
        }
        else {
            this.notifyErrorInvalid();
            this.setState({ selectedFile: '', newFileName: '' });
            this.fileName = "";
        }


    }

    render() {
        if (this.props.isLoggedIn === '') {
            return <div>{LOADER}</div>

        }
        if (!this.props.isLoggedIn) {
            return <Redirect to='/login' />;
        }
        return (
            <div className="layout-container">
                <Loader show={this.state.isLoadingFile} message={LOADER} backgroundStyle={{ opacity: 0.3, backgroundColor: '#fafafa94' }} >
                    <HeaderAC isLoading={this.state.isLoadingFile} logout={this.props.logout} />
                    <FileInput stopLoader={this.stopLoader} isLoading={this.state.isLoading} handleSelectedDate={this.handleSelectedDate} files={this.state.allFiles} selectedFile={this.state.newFileName} onSelect={(selectedFile) => this.handleSelectedFile(selectedFile)} onUpload={this.handleUpload} />
                    <ToastContainer autoClose={2000} />
                </Loader>
            </div>
        )
    }
}
export default FileUploadAgreementCancellation;
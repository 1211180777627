import ResultsAgreementCancellation from './results';
import * as stateActions from "../../actions/actions.js";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

const mapStateToProps = state => {
    let data = {
        isLoggedIn: state.login.isLoggedIn,
        agreementCancellationRecords: state.acResult.agreementCancellationRecords,
        exportCancelRecords: state.acResult.agreementCancellationExportRecords
    };

    return data;
};

const mapDispatchToProps = dispatch => ({
    getAgreementCancelRecordsOfDate: (date, recordsType, offset) => dispatch(stateActions.getAgreementCancelRecordsOfDate(date, recordsType, offset)),
    checkIsLoggedIn: () => dispatch(stateActions.checkIsLoggedIn()),
    logout: () => dispatch(stateActions.logout()),
    exportAgreementCancelCsv: (date, recordsType) => dispatch(stateActions.exportAgreementCancelCsv(date, recordsType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsAgreementCancellation));
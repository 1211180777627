import React from 'react';
import * as commonConstants from '../../../constants/constants';
const creditConstants = require('../../../constants/creditposting/constants');
const PAGE_MESSAGE_CONSTANTS = creditConstants.RESULT_PAGE_MESSAGE;

class CreditResultPageMessage extends React.Component {

    render() {

        let pageMessage;
        if (Number(this.props.recordsType) === commonConstants.SUCCESS_RECORDS_TYPE) {
            pageMessage = PAGE_MESSAGE_CONSTANTS.CREDITS_SUCCESS_INFO;
        } else if (Number(this.props.recordsType) === commonConstants.FAILURE_RECORDS_TYPE) {
            pageMessage = PAGE_MESSAGE_CONSTANTS.CREDITS_FAILURE_INFO;

        } else if (Number(this.props.recordsType) === commonConstants.INPROCESS_RECORDS_TYPE) {
            pageMessage = PAGE_MESSAGE_CONSTANTS.CREDITS_INPROCESS_INFO;
        } else {
            pageMessage = '';
        }

        return (
            <React.Fragment>
                <div className="message-info">
                    {pageMessage}
                </div>
            </React.Fragment>
        );
    }

}

export default CreditResultPageMessage;
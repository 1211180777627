import CreditResult from './results';
import * as loginStateActions from "../../../actions/actions";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as creditStateActions from "../../../actions/creditposting/actions";

const mapStateToProps = state => {
    let data = {
        isLoggedIn: state.login.isLoggedIn,
        creditResultRecords: state.creditResult.creditResultRecords,
        exportCreditRecords: state.creditResult.creditResultExportRecords
    };

    return data;
};

const mapDispatchToProps = dispatch => ({
    getCreditsResult: (fromDate, toDate, recordsType, offset, memberId) => dispatch(creditStateActions.getCreditsResult(fromDate, toDate, recordsType, offset, memberId)),
    logout: () => dispatch(loginStateActions.logout()),
    checkIsLoggedIn: () => dispatch(loginStateActions.checkIsLoggedIn()),
    exportCreditsResultCsv: (fromDate, toDate, recordsType, memberId) => dispatch(creditStateActions.exportCreditsResultCsv(fromDate, toDate, recordsType, memberId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditResult));
import React from 'react';
import HeaderDS from '../../common/headers/headerDS';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import * as commonConstants from '../../../constants/constants';
import DigiSignResultTable from './resultstable/resultstable';
import DigiSignResultFilterPanel from './resultsfilterpanel';
import DigiSignResultPageMessage from './resultpagemessage';
import { LOADER } from '../../../constants/constants';
import digiSignConstants from '../../../constants/digitalsignature/constants'

class DigitalSignatureResult extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFromDate: this.getCurrentDate(),
            selectedToDate: this.getCurrentDate(),
            recordsType: commonConstants.SUCCESS_RECORDS_TYPE,
            currentPaginateNumber: 0,
            isLoading: false,
            isExportCommand: false,
            isSendCommand: false,
            currentDate: this.getCurrentDate(),
            isExporting: false,
            isSending: false,
            memberId: '',
            clubId:'',
            isSearchCriteria: false,
            isSearched: false,
            lastSearchedMember: '',
            lastSearchedClubId: '',
            checkedRecords: []
        };
    }

    componentWillMount() {

        this
            .props
            .checkIsLoggedIn();
    }

    componentDidMount() {

        this.fetchRecords();
    }

    fetchRecords = () => {
        this.setState({ isLoading: true, checkedRecords: [] });

        let offset = this.state.currentPaginateNumber * commonConstants.DIGISIGN_RECORDS_LIMIT_PER_PAGE;
        let memberId = this
            .state
            .memberId
            .trim();
        let clubId = this.state.clubId;
        
        this
            .props
            .getDigiSignResult(this.state.selectedFromDate, this.state.selectedToDate, this.state.recordsType, offset, memberId,clubId);

    }

    componentWillReceiveProps(nextProps) {

        this.setState({ isLoading: false });

        if (this.state.isExportCommand && nextProps.exportDigiSignRecords && nextProps.exportDigiSignRecords.isExportSuccess) {
            this.setState({ isExportCommand: false, isExporting: false });
            if (!nextProps.exportDigiSignRecords.isExportRecord) {
                this.notifyExportInformation("No Data to export");
            }
        }
        if (this.state.isSendCommand && nextProps.emailSendStatus == digiSignConstants.EMAIL_SEND_STATUS.SUCCESS) {
            this.setState({ isSendCommand: false, isSending: false });
            this.notifyExportInformation("Emails will be sent in the upcoming batch");
        }
        else if (this.state.isSendCommand && nextProps.emailSendStatus == digiSignConstants.EMAIL_SEND_STATUS.FAILED) {
            this.setState({ isSendCommand: false, isSending: false });
            this.notifyError("Sending Emails faced an error");
        }
    }

    notifyExportInformation = (message) => {
        toast.info(message, { position: toast.POSITION.TOP_CENTER, onClose: this.handleRefresh });
    }


    notifyError = (message) => {
        toast.error(message, { position: toast.POSITION.TOP_CENTER, onClose: this.handleRefresh });
    }
    getCurrentDate = () => {

        let dateObj = new Date();

        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let date = dateObj.getDate();

        let formattedMonth = (month + 1) < 10
            ? '0' + (month + 1)
            : (month + 1);
        let formattedDate = date < 10
            ? '0' + date
            : date;

        let currentDate = year + '-' + formattedMonth + '-' + formattedDate;

        return currentDate;
    }

    handleSelectedDate = (event) => {

        const target = event.target;
        const name = target.name;

        let newDate;
        newDate = target.value;
        if (newDate === '') {
            newDate = this.getCurrentDate();
        }

        if (name === 'toDate') {
            this.setState({
                selectedToDate: newDate,
                currentPaginateNumber: 0,
                isSearchCriteria: true,
                isSearched: true,
                lastSearchedMember: this
                    .state
                    .memberId
                    .trim(),
                lastSearchedClubId : this.state.clubId
            }, this.fetchRecords);

        } else if (name === 'fromDate') {
            this.setState({
                selectedFromDate: newDate,
                currentPaginateNumber: 0,
                isSearchCriteria: true,
                isSearched: true,
                lastSearchedMember: this
                    .state
                    .memberId
                    .trim(),
                lastSearchedClubId : this.state.clubId
            }, this.fetchRecords);

        }

    };

    handleSelectedRecordsType = (event) => {

        let newRecordType = event.target.value;

        this.setState({
            recordsType: newRecordType,
            currentPaginateNumber: 0,
            isSearched: true,
            lastSearchedMember: this
                .state
                .memberId
                .trim(),
            lastSearchedClubId : this.state.clubId
        }, this.fetchRecords);

    }

    handleExport = () => {

        this.setState({ isExportCommand: true, isExporting: true });
        let memberId,clubId;
        if (this.state.isSearched) {
            memberId = this
                .state
                .memberId
                .trim();
            clubId = this.state.clubId;
        } else {
            memberId = this
                .state
                .lastSearchedMember
                .trim();
                clubId = this.state.lastSearchedClubId;
        }
        

        this
            .props
            .exportDigiSignResultCsv(this.state.selectedFromDate, this.state.selectedToDate, this.state.recordsType, memberId,clubId);

    };


    handleSend = () => {
        this.setState({ isSendCommand: true, isSending: true });
        let records = this.state.checkedRecords;

        this
            .props
            .sendDigiSignEmails(records);

    };


    handlePagination = (event) => {

        let pageNumberClicked = event;
        this.setState({
            currentPaginateNumber: pageNumberClicked - 1
        }, this.fetchRecords);
    }

    handleRefresh = () => {

        this.setState({ isLoading: true, checkedRecords: [] });

        let offset = this.state.currentPaginateNumber * commonConstants.DIGISIGN_RECORDS_LIMIT_PER_PAGE;
        let memberId,clubId;
        if (this.state.isSearched) {
            memberId = this
                .state
                .memberId
                .trim();
            clubId = this.state.clubId;

        } else {
            memberId = this
                .state
                .lastSearchedMember
                .trim();
            clubId = this.state.lastSearchedClubId;
        }

        this
            .props
            .getDigiSignResult(this.state.selectedFromDate, this.state.selectedToDate, this.state.recordsType, offset, memberId,clubId);
    }

    handleCheck = (event) => {
        debugger;
        let isChecked = event.target.checked;
        let idToSend = Number(event.target.dataset.recordid);
        let newCheckInRecords = [];
        if (isChecked) {
            newCheckInRecords = this.state.checkedRecords;
            newCheckInRecords.push(idToSend);
            this.setState({ checkedRecords: newCheckInRecords });
        }
        else {
            newCheckInRecords = this.state.checkedRecords.filter((x) => x !== idToSend);
            this.setState({ checkedRecords: newCheckInRecords });
        }

    }

    handleCheckAll = (event) => {
        debugger;
        let isChecked = event.target.checked;
        let newCheckInRecords = JSON.parse(event.target.dataset.records);

        if (isChecked) {
            this.setState({ checkedRecords: newCheckInRecords });
        }
        else {
            this.setState({ checkedRecords: [] });
        }
    }

    handleSearch = (event) => {

        if (!this.isSearchValid()  || (this.state.memberId === '' && this.state.clubId === '') ) 
        {
            event.preventDefault();
            return;
        }
        
        this.doSearch();

    }

    doSearch = () => {
        if (this.state.memberId.trim() !== '' || this.state.clubId !== '') {
            this.setState({
                isSearchCriteria: true,
                isSearched: true,
                lastSearchedMember: this
                .state
                .memberId
                .trim(),
                lastSearchedClubId:this.state.clubId
            })
            
            this.fetchRecords();
        }

    }

    handleMemberReset = (event) => {

        if (!this.isMemberSearchValid() ) {
            event.preventDefault();
            return;

        }

        this.setState({
            currentPaginateNumber: 0,
            memberId: '',
            isSearchCriteria: false,
            isSearched: false,
            lastSearchedMember: '',
        }, this.fetchRecords);

    }
    handleClubIdReset = (event) => {

        if (!this.isClubIdSearchValid()) {
            event.preventDefault();
            return;

        }

        this.setState({
            currentPaginateNumber: 0,
            clubId:'',
            isSearchCriteria: false,
            isSearched:false,
            lastSearchedClubId:''
        }, this.fetchRecords);

    }

    handleMemberSearchChange = (event) => {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });

        if (this.state.isSearched) {
            this.setState({ isSearched: false });
        }

    }

    handleClubIdSearchChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });

        if (this.state.isSearched) {
            this.setState({ isSearched: false });
        }
    }

    isSearchValid = () => {

        if (!this.state.isSearchCriteria && this.props.digiSignResultRecords && this.props.digiSignResultRecords.recordsCount === 0 )
        {
            return false;
        }
        return true;

    }
    isMemberSearchValid = () => {

        if (!this.state.isSearchCriteria && this.props.digiSignResultRecords && this.props.digiSignResultRecords.recordsCount === 0 && this.state.memberId === '')
        {
            return false;
        }
        return true;

    }
    isClubIdSearchValid = () => {

        if (!this.state.isSearchCriteria && this.props.digiSignResultRecords && this.props.digiSignResultRecords.recordsCount === 0 
            && this.state.clubId === '')
        {
            return false;
        }
        return true;

    }

    render() {
        if (this.props.isLoggedIn === '') {
            return <div>{LOADER}</div>

        }
        if (!this.props.isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return (
            <div className="layout-container">
                <HeaderDS logout={this.props.logout} />
                <main className="container container-for-digital-signature">
                    {this.state.isLoading && LOADER}
                    <h1 className="page-heading">Digital Signature Results</h1>
                    <div className="file-upload-list white-bg box-shadow">

                        <DigiSignResultFilterPanel
                            recordsType={this.state.recordsType}
                            isLoading={this.state.isLoading}
                            selectedFromDate={this.state.selectedFromDate}
                            selectedToDate={this.state.selectedToDate}
                            currentDate={this.state.currentDate}
                            isExporting={this.state.isExporting}
                            isSending={this.state.isSending}
                            handleSelectedRecordsType={this.handleSelectedRecordsType}
                            handleSelectedDate={this.handleSelectedDate}
                            handleExport={this.handleExport}
                            handleRefresh={this.handleRefresh}
                            handleSend={this.handleSend}
                            handleMemberSearchChange={this.handleMemberSearchChange}
                            handleClubIdSearchChange={this.handleClubIdSearchChange}
                            checkedRecords={this.state.checkedRecords}
                            memberId={this.state.memberId}
                            clubId={this.state.clubId}
                            isSearchCriteria={this.state.isSearchCriteria}
                            handleSearch={this.handleSearch}
                            handleMemberReset={this.handleMemberReset}
                            handleClubIdReset={this.handleClubIdReset}
                            records={this.props.digiSignResultRecords}
                            isMemberSearchValid={this.isMemberSearchValid()}
                            isClubIdSearchValid={this.isClubIdSearchValid()}
                            onKeyPress={this.handleOnKeyPressMemberSearch} /> {/* Hidden anchor tag for exporting a file  */}
                        <a
                            ref={link => this.link = link}
                            style={{
                                display: 'none'
                            }} />

                        <DigiSignResultTable
                            recordsType={this.state.recordsType}
                            isLoading={this.state.isLoading}
                            selectedFromDate={this.state.selectedFromDate}
                            selectedToDate={this.state.selectedToDate}
                            currentDate={this.state.currentDate}
                            records={this.props.digiSignResultRecords}
                            checkedRecords={this.state.checkedRecords}
                            currentPaginateNumber={this.state.currentPaginateNumber}
                            handlePagination={this.handlePagination}
                            handleCheck={this.handleCheck}
                            handleCheckAll={this.handleCheckAll}
                            isSearchCriteria={this.state.isSearchCriteria}
                            memberId={this.state.memberId} />

                        <DigiSignResultPageMessage recordsType={this.state.recordsType} />

                    </div>
                </main>
                <ToastContainer autoClose={2000} />
            </div>
        );
    }
}

export default DigitalSignatureResult;
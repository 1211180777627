import * as constants from "../constants/constants.js";
import axios from 'axios';

let handleError = (status) => {

    if (status === 401) {
        localStorage.removeItem('token');
        window
            .location
            .replace('/login');
    } else if (status === 500) {
        //Internal Server Error
        window
            .location
            .replace('/server-error');

    } else if (status === 0) {
        window
            .location
            .replace('/server-not-reachable');
    } else if (status === 400) {
        console.log('BAD REQUEST');

    } else {
        // localStorage.removeItem('token'); window.location.replace('/login');

    }
}

let handleResponse = (response) => {
    if (response.ok) {
        return response.json( // If invalid jwt token the page will get redirect to login.
        );
    } else if (response.status === 401) {
        return handleError(401);
    } else {
        return handleError(500);
    }
};

let getToken = () => {
    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }
    return token;
}

let withTimeout = (msecs, promise) => {
    const timeout = new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('timeout'));
        }, msecs);
    });
    return Promise.race([timeout, promise]);
}

const httpRequests = {
    get: (url) => {
        return withTimeout(10000, fetch(url, {
            method: 'get',
            headers: {
                Authorization: 'Token ' + getToken()
            }
        })).then((response) => {
            return handleResponse(response);
        }).catch((err) => {
            let errorString = err + '';
            if (errorString.includes('timeout') || errorString.includes('Failed to fetch')) {
                console.log('not reachable');
                return handleError(0); //unreachable server
            }
        });
    },
    post: (url, body) => {
        return withTimeout(10000, fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + getToken(),
                'Content-Type': 'application/json'
            }
        })).then((response) => {
            return handleResponse(response);
        }).catch((err) => {
            let errorString = err + '';
            if (errorString.includes('timeout') || errorString.includes('Failed to fetch')) {
                console.log('not reachable');
                return handleError(0); //unreachable server
            }
        })
    },
    postForm: (url, dataFile) => {
        let file = dataFile.data.file;
        let filename = dataFile.data.filename;
        const data = new FormData()
        data.append('file', file)
        data.append('filename', filename)
        return axios
            .post(url, data, {
            headers: {
                'Authorization': 'Token ' + getToken()
            }
        })
            .then((response) => {
                return handleResponse(response);
            })
            .catch(err => {
                return handleError(0); //unreachable server
            })
    },
    fileDownload: (url, filename) => {
        return fetch(url, {
            method: 'get',
            headers: {
                Authorization: 'Token ' + getToken()
            }
        })
            .then(response => {
                return response.blob()
            })
            .then(blob => {
                if (blob && blob.size) {
                    let anchor = document.createElement("a");
                    document.body.appendChild(anchor);

                    let objectUrl = window.URL.createObjectURL(blob);

                    anchor.href = objectUrl;
                    anchor.download = `${filename}.csv`;
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                    return {
                        isExportSuccess: true,
                        isExportRecord: true
                    };
                }
                return {
                    isExportSuccess: true,
                    isExportRecord: false
                };
            })
            .catch((err) => {
                let errorString = err + '';
                if (errorString.includes('timeout') || errorString.includes('Failed to fetch')) {
                    console.log('not reachable');
                    return handleError(0); //unreachable server
                }
            });
    }
};

export default httpRequests;

import * as types from '../../constants/creditposting/actiontypes';

export function getAllCreditFiles(date) {
    return {date: date, type: types.FETCH_CREDIT_FILES};
}

export function getAllCreditFilesFailure() {
    return {type: types.FETCH_CREDIT_FILES_FAILURE};
}

export function getAllCreditFilesSuccess(files) {
    return {type: types.FETCH_CREDIT_FILES_SUCCESS, files: files};
}

export function getCreditsResult(fromDate, toDate, recordsType, offset, memberId) {

    return {
        type: types.FETCH_CREDITS_RESULT,
        searchInfo: {
            fromDate: fromDate,
            toDate: toDate,
            recordsType: recordsType,
            offset: offset,
            memberId: memberId
        }
    };
}

export function getCreditsResultSuccess(records) {
    return {type: types.FETCH_CREDITS_RESULT_SUCCESS, creditsResult: records};
}

export function getCreditsResultFailure() {
    return {type: types.FETCH_CREDITS_RESULT_FAILURE};
}

export function exportCreditsResultCsv(fromDate, toDate, recordsType, memberId) {
    return {
        type: types.EXPORT_CREDITS_RESULT_CSV,
        searchInfo: {
            fromDate: fromDate,
            toDate: toDate,
            recordsType: recordsType,
            memberId: memberId
        }
    };
}

export function exportCreditsResultCsvSuccess(recordsInfo) {
    return {type: types.EXPORT_CREDITS_RESULT_CSV_SUCCESS, exportCreditsResult: recordsInfo};
}

export function exportCreditsResultCsvFailure() {
    return {type: types.EXPORT_CREDITS_RESULT_CSV_FAILURE};
}
import * as actionTypes from "../../constants/digitalsignature/actiontypes.js";

const initialState = {
   isLoggedIn: '',
   digiSignFiles: []
};

export const digiSignFilesReducer = (state = initialState, action) => {
   switch (action.type) {
      case actionTypes.FETCH_DIGISIGN_FILES_SUCCESS:
         return Object.assign({}, ...[state], { digiSignFiles: action.files });
      case actionTypes.FETCH_DIGISIGN_FILES_FAILURE:
         return Object.assign({}, ...[state], {});
      default:
         return state;
   }
};


export const FETCH_DIGISIGN_FILES = 'FETCH_DIGISIGN_FILES';
export const FETCH_DIGISIGN_FILES_SUCCESS = 'FETCH_DIGISIGN_FILES_SUCCESS';
export const FETCH_DIGISIGN_FILES_FAILURE = 'FETCH_DIGISIGN_FILES_FAILURE';


export const FETCH_DIGISIGN_EMAILTEMPLATES = 'FETCH_DIGISIGN_EMAILTEMPLATES';
export const FETCH_DIGISIGN_EMAILTEMPLATES_SUCCESS = 'FETCH_DIGISIGN_EMAILTEMPLATES_SUCCESS';
export const FETCH_DIGISIGN_EMAILTEMPLATES_FAILURE = 'FETCH_DIGISIGN_EMAILTEMPLATES_FAILURE';


export const FETCH_DIGISIGN_RESULT = 'FETCH_DIGISIGN_RESULT';
export const FETCH_DIGISIGN_RESULT_SUCCESS = 'FETCH_DIGISIGN_RESULT_SUCCESS';
export const FETCH_DIGISIGN_RESULT_FAILURE = 'FETCH_DIGISIGN_RESULT_FAILURE';


export const SEND_DIGISIGN_EMAILS = 'SEND_DIGISIGN_EMAILS';
export const SEND_DIGISIGN_EMAILS_SUCCESS = 'SEND_DIGISIGN_EMAILS_SUCCESS';
export const SEND_DIGISIGN_EMAILS_FAILURE = 'SEND_DIGISIGN_EMAILS_FAILURE';
export const SEND_DIGISIGN_EMAILS_RESET = 'SEND_DIGISIGN_EMAILS_RESET';

export const EXPORT_DIGISIGN_RESULT_CSV = 'EXPORT_DIGISIGN_RESULT_CSV';
export const EXPORT_DIGISIGN_RESULT_CSV_SUCCESS = 'EXPORT_DIGISIGN_RESULT_CSV_SUCCESS';
export const EXPORT_DIGISIGN_RESULT_CSV_FAILURE = 'EXPORT_DIGISIGN_RESULT_CSV_FAILURE';

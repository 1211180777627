import React from 'react';
import {NavLink, Link} from "react-router-dom";

class HeaderHome extends React.Component {

    render() {
        return (
            <header className="dashboard-header">
                <Link to="/home" className="toggle-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </Link>
                <Link to="/home" className="logo-wrapper">
                    <img
                        className="logo"
                        alt="Crunch Logo"
                        src="https://dj7w0h47bhjwk.cloudfront.net/assets/core/logo-f0321dadbee0b6b8ec6d98159305c76e56590ff97a88d28d4eb2400b3baafe49.svg"/>
                </Link>
                <button onClick={this.props.logout} className="logout-btn btn">
                    <i className="fas fa-sign-out-alt"></i>
                    Logout</button>

                <ul className="dashboard-nav">
                    <li>
                        <NavLink
                            exact
                            activeStyle={{
                            color: 'red',
                            textDecoration: 'none'
                        }}
                            to="/home">Home
                            <span className="sr-only"></span>
                        </NavLink>
                    </li>
                </ul>
            </header>
        )
    }
}
export default HeaderHome;
import * as path from "../constants/constants.js";
import httpRequests from "../commonUtils/httpRequestHandler";


export const storeData = (data) => {
    let url = process.env.REACT_APP_API_URL + path.UPLOAD_DATA_AC_URL;
    httpRequests.postForm(url, data);
}

export const fetchFiles = (data) => {
    let url = process.env.REACT_APP_API_URL + path.FETCH_FILES_AC_URL + `/?date=${data}`;
    return httpRequests.get(url);
}

export const fetchAgreementsCancellationRecordsDate = (searchInfo) => {

    let date = searchInfo.searchInfo.date;
    let recordsType = searchInfo.searchInfo.recordsType;
    let offset = searchInfo.searchInfo.offset;
    let recordsTypeParam;

    switch (recordsType) {
        case '0': recordsTypeParam = 'success'; break;
        case '1': recordsTypeParam = 'failure'; break;
        case '2': recordsTypeParam = 'inprocess'; break;
        default: recordsTypeParam = 'success';
    }

    let url = process.env.REACT_APP_API_URL + `/agreementCancellation/records/?date=${date}&recordsType=${recordsTypeParam}&offset=${offset}`;

    return httpRequests.get(url);
}

export const exportAgreementsCancellationRecords = (searchInfo) => {

    let date = searchInfo.searchInfo.date;
    let recordsType = searchInfo.searchInfo.recordsType;

    let recordsTypeParam;

    switch (recordsType) {
        case '0': recordsTypeParam = 'success'; break;
        case '1': recordsTypeParam = 'failure'; break;
        case '2': recordsTypeParam = 'inprocess'; break;
        default: recordsTypeParam = 'success';
    }

    let url = process.env.REACT_APP_API_URL + `/agreementCancellation/export/?date=${date}&recordsType=${recordsTypeParam}`;

    return httpRequests.get(url);

}

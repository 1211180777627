import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../constants/actionTypes.js";
import * as actions from "../actions/actions.js";
import * as LOGINAPI from "../commonUtils/authentication.js";
import * as AGREEMENTAPI from "../commonUtils/agreementsApi.js";
import * as CREDITAPI from '../commonUtils/creditapi';
import * as creditActionTypes from "../constants/creditposting/actiontypes.js";
import * as creditActions from "../actions/creditposting/actions";
import * as invoiceActionTypes from "../constants/invoicecreation/actiontypes.js";
import * as invoiceActions from "../actions/invoicecreation/actions";
import * as digiSignActionTypes from "../constants/digitalsignature/actiontypes.js";
import * as digiSignActions from "../actions/digitalsignature/actions";
import * as DIGISIGNAPI from '../commonUtils/digiSignApi';
import * as INVOICEAPI from '../commonUtils/invoiceApi';

export function* loginSaga(loginCredentials) {
    try {
        let loginResult = yield call(LOGINAPI.login, loginCredentials);
        yield put(actions.loginSuccess(loginResult));
    } catch (error) {
        yield put(actions.loginFailure(error));
    }
}

export function* checkLogin() {
    try {
        let token;

        token = localStorage.getItem('token');

        let isLoggedIn = false;
        let loginResult = {
            isLoggedIn: isLoggedIn,
            loginError: {
                status: true,
                verifyLoginError: true
            }
        }
        if (token != null) {
            //check for valid token
            let isTokenValid = yield call(LOGINAPI.checkTokenValidity);
            if (isTokenValid) {
                isLoggedIn = true;
                loginResult = {
                    isLoggedIn: isLoggedIn,
                    loginError: {
                        status: false,
                        verifyLoginError: false
                    }
                }
            }
        }

        yield put(actions.loginSuccess(loginResult));
    } catch (err) {
        yield put(actions.loginFailure(err));
    }
}

function getDistinctFiles(allData) {
    const keyValueArray = allData.map(entry => [entry['CsvFileName'], entry]);
    const map = new Map(keyValueArray);
    return Array.from(map.values());
}

//saga function to fetch the list of files from DB
export function* fetchFiles(date) {
    try {
        let files = yield call(AGREEMENTAPI.fetchFiles, date.date);
        let distinctError = getDistinctFiles(files.errorlog);
        let distinctUser = getDistinctFiles(files.userdata);
        files = distinctError.concat(distinctUser);
        let distinctFiles = getDistinctFiles(files);
        let mappedFiles = distinctFiles.map((file) => {
            if (typeof file.IsCancelled === "undefined") {
                return Object.defineProperty(file, 'status', {
                    value: 'Processed',
                    writable: true
                });
            } else {
                if (file.IsCancelled == 0) {
                    return Object.defineProperty(file, 'status', {
                        value: 'In Process',
                        writable: true
                    });
                } else {
                    return Object.defineProperty(file, 'status', {
                        value: 'Processed',
                        writable: true
                    });
                }
            }

        });
        yield put(actions.getAllFilesSuccess(mappedFiles));

    } catch (err) {
        yield put(actions.getAllFilesFailure(err));
    }
}

//saga function to store the data of file uploaded into DB
export function* storeData(data) {
    try {
        let isProcessed = yield call(AGREEMENTAPI.storeData, data);
        yield put(actions.storeDataSuccess(isProcessed));
    } catch (err) {
        yield put(actions.storeDataFailure(err));
    }
}

export function* logout() {
    try {
        // yield call(delay,1000);
        localStorage.removeItem("token");
        let isLoggedIn = false;
        yield put(actions.logoutSuccess(isLoggedIn));
    } catch (err) {
        yield put(actions.logoutFailure(err));
    }
}

export function* updateRememberMe(rememberMe) {
    try {
        let updatedRememberMe = rememberMe.rememberMe;
        yield put(actions.updateRememberMeSuccess(updatedRememberMe));
    } catch (err) {
        yield put(actions.updateRememberMeFailure());
    }
}

export function* exportAgreementsCancellationRecords(searchInfo) {

    try {
        let exportRecordsInfo = yield call(AGREEMENTAPI.exportAgreementsCancellationRecords, searchInfo);
        yield put(actions.exportAgreementCancelCsvSuccess(exportRecordsInfo));
    } catch (err) {
        yield put(actions.exportAgreementCancelCsvFailure());
    }

}

export function* fetchAgreementsCancellationRecords(searchInfo) {

    try {
        let records = yield call(AGREEMENTAPI.fetchAgreementsCancellationRecordsDate, searchInfo);
        yield put(actions.getAgreementCancelRecordsOfDateSuccess(records));
    } catch (err) {
        yield put(actions.getAgreementCancelRecordsOfDateFailure());
    }

}

//saga function to fetch the list of files from DB
export function* fetchCreditFiles(date) {
    try {
        let files = yield call(CREDITAPI.fetchCreditFiles, date.date);
        yield put(creditActions.getAllCreditFilesSuccess(files.files));

    } catch (err) {
        yield put(creditActions.getAllCreditFilesFailure(err));
    }
}

export function* fetchCreditResult(searchInfo) {

    try {
        let records = yield call(CREDITAPI.fetchCreditResult, searchInfo);

        yield put(creditActions.getCreditsResultSuccess(records));
    } catch (err) {

        yield put(creditActions.getCreditsResultFailure());
    }

}

export function* exportCreditResult(searchInfo) {

    try {
        let exportRecordsInfo = yield call(CREDITAPI.exportCreditResult, searchInfo);
        yield put(creditActions.exportCreditsResultCsvSuccess(exportRecordsInfo));
    } catch (err) {
        yield put(creditActions.exportCreditsResultCsvFailure());
    }

}

//saga function to fetch the list of invoice files from DB
export function* fetchInvoiceFiles(date) {
    try {
        let files = yield call(INVOICEAPI.fetchInvoiceFiles, date.date);
        yield put(invoiceActions.getAllInvoiceFilesSuccess(files.files));
    } catch (err) {
        yield put(invoiceActions.getAllInvoiceFilesFailure(err));
    }
}

export function* fetchInvoiceResult(searchInfo) {

    try {
        let records = yield call(INVOICEAPI.fetchInvoiceResult, searchInfo);
        yield put(invoiceActions.getInvoiceResultSuccess(records));
    } catch (err) {
        yield put(invoiceActions.getInvoiceResultFailure());
    }

}

export function* exportInvoiceResult(searchInfo) {

    try {
        let exportRecordsInfo = yield call(INVOICEAPI.exportInvoiceResult, searchInfo);
        yield put(invoiceActions.exportInvoiceResultCsvSuccess(exportRecordsInfo));
    } catch (err) {
        yield put(invoiceActions.exportInvoiceResultCsvFailure());
    }
}

export function* fetchDigiSignResult(searchInfo) {

    try {
        let records = yield call(DIGISIGNAPI.fetchDigiSignResult, searchInfo);
        
        yield put(digiSignActions.getDigiSignResultSuccess(records));
    } catch (err) {
        yield put(digiSignActions.getDigiSignResultFailure());
    }

}

export function* fetchDigiSignTemplates() {

    try {
        let templates = yield call(DIGISIGNAPI.fetchDigiSignTemplates);
        
        yield put(digiSignActions.getAllDigiSignTemplatesSuccess(templates));
    } catch (err) {
        yield put(digiSignActions.getAllDigiSignTemplatesFailure());
    }

}

export function* fetchDigiSignFiles(date) {
    try {
        let files = yield call(DIGISIGNAPI.fetchDigiSignFiles, date.date);
        
        yield put(digiSignActions.getAllDigiSignFilesSuccess(files));
    } catch (err) {
        yield put(digiSignActions.getAllDigiSignFilesFailure());
    }
}

export function* sendDigiSignEmails(records) {
    try {
        yield put(digiSignActions.sendDigiSignEmailsReset());
        let result = yield call(DIGISIGNAPI.sendDigiSignEmails, records.records);
        yield put(digiSignActions.sendDigiSignEmailsSuccess());
    } catch (err) {
        yield put(digiSignActions.sendDigiSignEmailsFailure());
    }

}

export function* exportDigiSignResultCsv(searchInfo) {

    try {
        let exportRecordsInfo = yield call(DIGISIGNAPI.exportDigiSignResultCsv, searchInfo);
        yield put(digiSignActions.exportDigiSignResultCsvSuccess(exportRecordsInfo));
    } catch (err) {
        yield put(digiSignActions.exportDigiSignResultCsvFailure());
    }
}


// Watchers
//watching action type of LOGIN
export function* watchLoginSaga() {
    yield takeEvery(actionTypes.LOGIN, loginSaga);
}

//watching action type of CHECK_LOGIN
export function* watchCheckLoginSaga() {
    yield takeEvery(actionTypes.CHECK_LOGIN, checkLogin);
}

//watching action type of FETCH_FILES
export function* watchGetFilesSaga() {
    yield takeEvery(actionTypes.FETCH_FILES, fetchFiles);
}

//watching action type of STORE_DATA
export function* watchStoreDataSaga() {
    yield takeEvery(actionTypes.STORE_DATA, storeData);
}

export function* watchLogoutSaga() {
    yield takeEvery(actionTypes.LOGOUT, logout);
}

export function* watchUpdateRememberMeSaga() {
    yield takeEvery(actionTypes.UPDATE_REMEMBER_ME, updateRememberMe);
}

export function* watchExportAgreementsCancellationRecordsSaga() {
    yield takeEvery(actionTypes.EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE, exportAgreementsCancellationRecords);
}

export function* watchFetchAgreementsCancellationRecordsSaga() {
    yield takeEvery(actionTypes.FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE, fetchAgreementsCancellationRecords);
}

//watching action type of FETCH_CREDIT_FILES
export function* watchGetCreditFilesSaga() {
    yield takeEvery(creditActionTypes.FETCH_CREDIT_FILES, fetchCreditFiles);
}

//watching action type of FETCH_CP_FILES
export function* watchGetCreditResultSaga() {
    yield takeEvery(creditActionTypes.FETCH_CREDITS_RESULT, fetchCreditResult);
}

//watching action type of FETCH_CP_FILES
export function* watchExportCreditResultSaga() {
    yield takeEvery(creditActionTypes.EXPORT_CREDITS_RESULT_CSV, exportCreditResult);
}

//watching action type of FETCH_INVOICE_FILES
export function* watchGetInvoiceFilesSaga() {
    yield takeEvery(invoiceActionTypes.FETCH_INVOICE_FILES, fetchInvoiceFiles);
}
//watching action type of FETCH_INVOICE_RESULT
export function* watchGetInvoiceResultSaga() {
    yield takeEvery(invoiceActionTypes.FETCH_INVOICE_RESULT, fetchInvoiceResult);
}
//watching action type of FETCH_INVOICE_FILES
export function* watchExportInvoiceResultSaga() {
    yield takeEvery(invoiceActionTypes.EXPORT_INVOICE_RESULT_CSV, exportInvoiceResult);
}

//watching action type of FETCH_DIGISIGN_RESULTS
export function* watchGetDigiSignResultSaga() {
    yield takeEvery(digiSignActionTypes.FETCH_DIGISIGN_RESULT, fetchDigiSignResult);
}

export function* watchGetDigiSignTemplatesSaga() {
    yield takeEvery(digiSignActionTypes.FETCH_DIGISIGN_EMAILTEMPLATES, fetchDigiSignTemplates);
}

export function* watchGetDigiSignFilesSaga() {
    yield takeEvery(digiSignActionTypes.FETCH_DIGISIGN_FILES, fetchDigiSignFiles);
}

export function* watchSendDigiSignEmailsSaga() {
    yield takeEvery(digiSignActionTypes.SEND_DIGISIGN_EMAILS, sendDigiSignEmails);
}

export function* watchExportDigiSignResultSaga() {
    yield takeEvery(digiSignActionTypes.EXPORT_DIGISIGN_RESULT_CSV, exportDigiSignResultCsv);
}


// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        watchLoginSaga(),
        watchCheckLoginSaga(),
        watchGetFilesSaga(),
        watchStoreDataSaga(),
        watchLogoutSaga(),
        watchUpdateRememberMeSaga(),
        watchExportAgreementsCancellationRecordsSaga(),
        watchFetchAgreementsCancellationRecordsSaga(),
        watchGetCreditFilesSaga(),
        watchGetCreditResultSaga(),
        watchExportCreditResultSaga(),
        watchGetInvoiceFilesSaga(),
        watchGetInvoiceResultSaga(),
        watchExportInvoiceResultSaga(),
        watchGetDigiSignResultSaga(),
        watchGetDigiSignTemplatesSaga(),
        watchGetDigiSignFilesSaga(),
        watchSendDigiSignEmailsSaga(),
        watchExportDigiSignResultSaga()
    ]);
}
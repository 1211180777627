import  CreditFileUpload  from './fileupload';
import * as loginStateActions from "../../../actions/actions"; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as creditStateActions from "../../../actions/creditposting/actions";


const mapStateToProps = state => {
    let data = {
        isLoggedIn:state.login.isLoggedIn,
        files:state.creditFiles.files,
    };

    return data;
};

const mapDispatchToProps = dispatch => ({
    getAllCreditFiles: (date)=> dispatch(creditStateActions.getAllCreditFiles(date)),
    logout: () => dispatch(loginStateActions.logout()),
    checkIsLoggedIn: () => dispatch(loginStateActions.checkIsLoggedIn()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditFileUpload));

import React from 'react';
import { Link } from "react-router-dom";

class ActivityList extends React.Component {

    render() {
        return (
            <div className="actions-list white-bg box-shadow">
                <div className="list">
                    <p>
                        <Link to="/home/agreementCancellation/fileUpload">Agreement Cancellation</Link>
                        - Using Member Agreement ID, mark a current/future date for an Agreement to be
                        cancelled
                    </p>
                </div>
                <div className="list">
                    <p>
                        <Link to="/home/creditPosting/fileUpload">Credit Posting</Link>
                        - Using Member ID, post credits to a member account for any payment done. These
                        credits can be posted against a Member account or for a particular invoice of a
                        member
                    </p>
                </div>
                <div className="list">
                    <p>
                        <Link to="/home/invoiceCreation/fileUpload">Invoice Creation</Link>
                        - Using Member ID and item information, creates invoices of a Crunch member
                    </p>
                </div>
                <div className="list">
                    <p>
                        <Link to="/home/digitalsignature/results">Digital Signature</Link>
                        - Using Member ID and AgreementID ,triggers emails for a Crunch member
                    </p>
                </div>
 
            </div>
        );
    }

}

export default ActivityList;
import React from 'react';
import moment from 'moment';

class AgreementCancellationInProcessResultRecord extends React.Component {
    render() {
        return (
            <tr>
                <td>{this.props.record.CsvFileName}</td>
                <td>{this.props.record.MemberId}</td>
                <td>{this.props.record.MemberAgreementId}</td>
                <td>{moment(this.props.record.CancellationEffectiveDate).format('MMMM DD, YYYY')}</td>
                <td>{this.props.record.MemberNoteStatus === 1
                        ? 'Processed'
                        : 'In Process'}</td>
                <td>{this.props.record.IsCancelled === 1
                        ? 'Processed'
                        : 'In Process'}</td>
            </tr>
        );
    }
}

export default AgreementCancellationInProcessResultRecord;
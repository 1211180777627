import  FileUploadAgreementCancellation  from './fileUpload';
import * as stateActions from "../../actions/actions.js";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const mapStateToProps = state => {
    let data = {
        files:state.files.files,
        isLoggedIn:state.login.isLoggedIn
    };

    return data;
};

const mapDispatchToProps = dispatch => ({
    getAllFiles: (date)=> dispatch(stateActions.getAllFiles(date)),
    uploadData: (data) => dispatch(stateActions.storeData(data)),
    logout: () => dispatch(stateActions.logout()),
    checkIsLoggedIn: () => dispatch(stateActions.checkIsLoggedIn()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileUploadAgreementCancellation));
import React from 'react';

class AgreementCancellationFailureResultRecord extends React.Component {
    render() {
        return (
            <tr>
                <td>{this.props.record.CsvFileName}</td>
                <td>{this.props.record.MemberId}</td>
                <td>{this.props.record.MemberAgreementId}</td>
                <td>{this.props.record.ErrorCode}</td>
                <td>{this.props.record.ErrorMessage}</td>
                <td>{this.props.record.ErrorType}</td>
            </tr>
        );
    }
}

export default AgreementCancellationFailureResultRecord;
import React from 'react';
import HeaderCP from '../../common/headers/headerCP';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import * as commonConstants from '../../../constants/constants';
import CreditResultTable from './resultstable/resultstable';
import CreditResultFilterPanel from './resultsfilterpanel';
import CreditResultPageMessage from './resultpagemessage';
import { LOADER } from '../../../constants/constants';

class CreditResult extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFromDate: this.getCurrentDate(),
            selectedToDate: this.getCurrentDate(),
            recordsType: commonConstants.SUCCESS_RECORDS_TYPE,
            currentPaginateNumber: 0,
            isLoading: false,
            isExportCommand: false,
            currentDate: this.getCurrentDate(),
            isExporting: false,
            memberId: '',
            isSearchCriteria: false,
            isMemberSearched: false,
            lastSearchedMember: ''
        };
    }

    componentWillMount() {
        this
            .props
            .checkIsLoggedIn();
    }

    componentDidMount() {
        this.fetchRecords();
    }

    fetchRecords = () => {
        this.setState({ isLoading: true });

        let offset = this.state.currentPaginateNumber * commonConstants.CREDIT_RECORDS_LIMIT_PER_PAGE;
        let memberId = this
            .state
            .memberId
            .trim();
        this
            .props
            .getCreditsResult(this.state.selectedFromDate, this.state.selectedToDate, this.state.recordsType, offset, memberId);

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isLoading: false });
        
        if (this.state.isExportCommand && nextProps.exportCreditRecords && nextProps.exportCreditRecords.isExportSuccess) {
            this.setState({ isExportCommand: false, isExporting: false });
            if (!nextProps.exportCreditRecords.isExportRecord) {
                this.notifyExportInformation("No Data to export");
            }
        }
    }

    notifyExportInformation = (message) => {
        toast.info(message, { position: toast.POSITION.TOP_CENTER });
    }

    getCurrentDate = () => {

        let dateObj = new Date();

        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let date = dateObj.getDate();

        let formattedMonth = (month + 1) < 10
            ? '0' + (month + 1)
            : (month + 1);
        let formattedDate = date < 10
            ? '0' + date
            : date;

        let currentDate = year + '-' + formattedMonth + '-' + formattedDate;

        return currentDate;
    }

    handleSelectedDate = (event) => {

        const target = event.target;
        const name = target.name;

        let newDate;
        newDate = target.value;
        if (newDate === '') {
            newDate = this.getCurrentDate();
        }

        if (name === 'toDate') {
            this.setState({
                selectedToDate: newDate,
                currentPaginateNumber: 0,
                isSearchCriteria: true,
                isMemberSearched: true,
                lastSearchedMember: this
                    .state
                    .memberId
                    .trim()
            }, this.fetchRecords);

        } else if (name === 'fromDate') {
            this.setState({
                selectedFromDate: newDate,
                currentPaginateNumber: 0,
                isSearchCriteria: true,
                isMemberSearched: true,
                lastSearchedMember: this
                    .state
                    .memberId
                    .trim()
            }, this.fetchRecords);

        }

    };

    handleSelectedRecordsType = (event) => {

        let newRecordType = event.target.value;

        this.setState({
            recordsType: newRecordType,
            currentPaginateNumber: 0,
            isMemberSearched: true,
            lastSearchedMember: this
                .state
                .memberId
                .trim()
        }, this.fetchRecords);

    }

    handleExport = () => {

        this.setState({ isExportCommand: true, isExporting: true });
        let memberId;
        if (this.state.isMemberSearched) {
            memberId = this
                .state
                .memberId
                .trim();

        } else {
            memberId = this
                .state
                .lastSearchedMember
                .trim();
        }

        this
            .props
            .exportCreditsResultCsv(this.state.selectedFromDate, this.state.selectedToDate, this.state.recordsType, memberId);

    };

    handlePagination = (event) => {

        let pageNumberClicked = event;
        this.setState({
            currentPaginateNumber: pageNumberClicked - 1
        }, this.fetchRecords);
    }

    handleRefresh = () => {

        this.setState({ isLoading: true });

        let offset = this.state.currentPaginateNumber * commonConstants.CREDIT_RECORDS_LIMIT_PER_PAGE;
        let memberId;
        if (this.state.isMemberSearched) {
            memberId = this
                .state
                .memberId
                .trim();

        } else {
            memberId = this
                .state
                .lastSearchedMember
                .trim();
        }
        this
            .props
            .getCreditsResult(this.state.selectedFromDate, this.state.selectedToDate, this.state.recordsType, offset, memberId);
    }

    handleMemberSearch = (event) => {

        if (!this.isMemberSearchValid() || this.state.memberId === '') {
            event.preventDefault();
            return;

        }

        this.doMemberSearch();

    }

    doMemberSearch = () => {
        if (this.state.memberId.trim() !== '') {
            this.setState({
                isSearchCriteria: true,
                isMemberSearched: true,
                lastSearchedMember: this
                    .state
                    .memberId
                    .trim()
            })
            this.fetchRecords();
        }

    }

    handleMemberReset = (event) => {

        if (!this.isMemberSearchValid()) {
            event.preventDefault();
            return;

        }

        this.setState({
            currentPaginateNumber: 0,
            memberId: '',
            isSearchCriteria: false,
            isMemberSearched: false,
            lastSearchedMember: ''
        }, this.fetchRecords);

    }

    handleSearchChange = (event) => {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });

        if (this.state.isMemberSearched) {
            this.setState({ isMemberSearched: false });
        }

    }

    handleOnKeyPressMemberSearch = (event) => {
        if (event.key === 'Enter') {
            this.doMemberSearch();
        }
    }

    isMemberSearchValid = () => {

        if (!this.state.isSearchCriteria && this.props.creditResultRecords && this.props.creditResultRecords.recordsCount === 0 && this.state.memberId === '') {
            return false;
        }
        return true;

    }

    render() {

        if (this.props.isLoggedIn === '') {
            return <div>{LOADER}</div>

        }
        if (!this.props.isLoggedIn) {
            return <Redirect to='/login' />;
        }

        return (
            <div className="layout-container">
                <HeaderCP logout={this.props.logout} />
                <main className="container">
                    {this.state.isLoading && LOADER}
                    <h1 className="page-heading">Credit Posting Results</h1>
                    <div className="file-upload-list white-bg box-shadow">

                        <CreditResultFilterPanel
                            recordsType={this.state.recordsType}
                            isLoading={this.state.isLoading}
                            selectedFromDate={this.state.selectedFromDate}
                            selectedToDate={this.state.selectedToDate}
                            currentDate={this.state.currentDate}
                            isExporting={this.state.isExporting}
                            handleSelectedRecordsType={this.handleSelectedRecordsType}
                            handleSelectedDate={this.handleSelectedDate}
                            handleExport={this.handleExport}
                            handleRefresh={this.handleRefresh}
                            handleSearchChange={this.handleSearchChange}
                            memberId={this.state.memberId}
                            isSearchCriteria={this.state.isSearchCriteria}
                            handleMemberSearch={this.handleMemberSearch}
                            handleMemberReset={this.handleMemberReset}
                            records={this.props.creditResultRecords}
                            isMemberSearchValid={this.isMemberSearchValid()}
                            onKeyPress={this.handleOnKeyPressMemberSearch} /> {/* Hidden anchor tag for exporting a file  */}
                        <a
                            ref={link => this.link = link}
                            style={{
                                display: 'none'
                            }} />

                        <CreditResultTable
                            recordsType={this.state.recordsType}
                            isLoading={this.state.isLoading}
                            selectedFromDate={this.state.selectedFromDate}
                            selectedToDate={this.state.selectedToDate}
                            currentDate={this.state.currentDate}
                            records={this.props.creditResultRecords}
                            currentPaginateNumber={this.state.currentPaginateNumber}
                            handlePagination={this.handlePagination}
                            isSearchCriteria={this.state.isSearchCriteria}
                            memberId={this.state.memberId} />

                        <CreditResultPageMessage recordsType={this.state.recordsType} />

                    </div>
                </main>
                <ToastContainer autoClose={2000} />
            </div>
        );
    }

}

export default CreditResult;
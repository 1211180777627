import Home from './home';
import * as stateActions from "../../actions/actions.js";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

const mapStateToProps = state => {
    let data = {
        isLoggedIn: state.login.isLoggedIn,
        logoutError: state.login.logoutError || ''
    };
    return data;
};
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(stateActions.logout()),
    checkIsLoggedIn: () => dispatch(stateActions.checkIsLoggedIn())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
import * as actionTypes from "../../constants/invoicecreation/actiontypes.js";

const initialState = {
    files: [],
};

export const getInvoiceFilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INVOICE_FILES_SUCCESS:
            return Object.assign({}, ...[state], { files: action.files });
        default:
            return state;
    }
};


import * as actionTypes from "../constants/actionTypes.js";

const initialState = {
    isLoggedIn: '',
    loginError:{
        status:false,
        usernameError:false,
        passwordError:false,
        usernameErrorMessage:'',
        passwordErrorMessage:''
      },
      logoutError:'',
      rememberMe:true
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
           return  Object.assign({},...[state],{isLoggedIn:action.loginResult.isLoggedIn,loginError:action.loginResult.loginError});
        case actionTypes.LOGIN_FAILURE:
           return  Object.assign({},...[state],{isLoggedIn:action.loginErrorResult.isLoggedIn,loginError:action.loginError});
        case actionTypes.LOGOUT_SUCCESS:
           return  Object.assign({},...[state],{isLoggedIn:action.isLoggedIn});
        case actionTypes.LOGOUT_FAILURE:
           return  Object.assign({},...[state],{logoutError:action.error});
        case actionTypes.UPDATE_REMEMBER_ME_SUCCESS:
           return  Object.assign({},...[state],{rememberMe:action.rememberMe});
        case actionTypes.UPDATE_REMEMBER_ME_FAILURE:
           return  Object.assign({},...[state]);    
        default:
            return state;

    }
};


import React from 'react';
import {Link} from "react-router-dom";

class Header extends React.Component {

    render() {
        return (
            <header className="dashboard-header">
                <Link to="/home" className="toggle-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </Link>
                <Link to="/home" className="logo-wrapper">
                    <img
                        className="logo"
                        alt="Crunch Logo"
                        src="https://dj7w0h47bhjwk.cloudfront.net/assets/core/logo-f0321dadbee0b6b8ec6d98159305c76e56590ff97a88d28d4eb2400b3baafe49.svg"/>
                </Link>
            </header>
        )
    }
}
export default Header;
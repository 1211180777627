import React from 'react';
import Loader from 'react-loader-advanced';
import { LOADER } from '../../../constants/constants';
import { ToastContainer, toast } from 'react-toastify';
import FileList from './filelist';
import { getCurrentDate } from '../../../commonUtils/utility';
import HeaderDS from '../../common/headers/headerDS';

class DigiSignFiles extends React.Component {

    constructor(props) {
        
        super(props);
        this.state = {
            allFiles: [],
            isLoading: false
        };

        this.handleRefreshFiles = this.handleRefreshFiles.bind(this);
        this.handleSelectedDate = this.handleSelectedDate.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isLoading: false
        })
        
        this.setState({
            allFiles: nextProps.files.digiSignFiles || []
        });

    }

    componentWillMount() {
        
        this.props.checkIsLoggedIn();
    }

    componentDidMount() {
        
        this.setState({
            isLoading: true
        });
        
        this.props.getAllDigiSignFiles(getCurrentDate());
    }

    handleRefreshFiles(date) {
        this.setState({ isLoading: true });
        this.props.getAllDigiSignFiles(date);
    }

    handleSelectedDate(date) {
        this.setState({
            isLoading: true
        });
        this.props.getAllDigiSignFiles(date);
    }

    render() {
        return (
            <div className="layout-container">
                    <HeaderDS isLoading={this.state.isLoading} logout={this.props.logout} />
                    <FileList
                        isLoading={this.state.isLoading}
                        handleSelectedDate={(selectedDate) => this.handleSelectedDate(selectedDate)}
                        files={this.state.allFiles}
                        handleRefreshFiles={(selectedDate) => this.handleRefreshFiles(selectedDate)}
                    />
                    <ToastContainer autoClose={2000} />
            </div>
        );
    }

}

export default DigiSignFiles;
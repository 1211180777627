import React from 'react';
import FileList from './filelist';
import Loader from 'react-loader-advanced';
import { LOADER, REFRESH_IMAGE } from '../../../constants/constants';
import '../../../static/css/App.css';
import '../../../static/css/index.css';
import '../../../static/css/agreementCancellationResults.css';
import * as commonConstants from '../../../constants/constants';
import '../../../static/css/invoicecreation/invoice-creation-files-upload.css';
import { get45DaysBeforeDate, getCurrentDate } from '../../../commonUtils/utility';
import invoiceConstants from '../../../constants/invoicecreation/constants';

class FileInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: getCurrentDate(),
            selectedDate: getCurrentDate()
        }

        this.handleKeyDownDate = this
            .handleKeyDownDate
            .bind(this);
        this.handleSelectedDate = this
            .handleSelectedDate
            .bind(this);
        this.handleRefreshFiles = this
            .handleRefreshFiles
            .bind(this);
        this.returnToCurrent = this
            .returnToCurrent
            .bind(this);
    }

    returnToCurrent() {
        let newDate = getCurrentDate();
        this.setState({ selectedDate: newDate });
        this.props.handleSelectedDate(newDate);
        this.refs.fileInput.value = null;
    }

    handleKeyDownDate(e) {
        e.preventDefault();
    }

    handleSelectedDate(event) {
        let newDate = event.target.value;

        if (!newDate) {
            newDate = getCurrentDate();
        }
        this.setState({ selectedDate: newDate });

        this
            .props
            .handleSelectedDate(newDate);

    }

    handleRefreshFiles() {
        this
            .props
            .handleRefreshFiles(this.state.selectedDate);
    }

    render() {
        return (
            <React.Fragment>
                <main className="container">
                    <h1 className="page-heading">Invoice Creation File Upload</h1>
                    <div className="file-upload-list white-bg box-shadow">
                        <div className="table-controls clearfix">
                            <div className="datepicker-control">
                                <input
                                    onKeyDown={this.handleKeyDownDate}
                                    onChange={this.handleSelectedDate}
                                    type="date"
                                    id="datepicker"
                                    className="form-control"
                                    value={this.state.selectedDate}
                                    max={this.state.currentDate}
                                    min={get45DaysBeforeDate(new Date())} />
                            </div>

                            <div className="upload-btn-wrapper">
                                {!this.props.selectedFile
                                    ? <button className="btnUpload" disabled>
                                        Upload
                                        </button>
                                    : <button className="btnUpload" onClick={(event) => this.props.onUpload(event)}>
                                        Upload
                                    </button>}
                            </div>
                            <div className="browse-btn-wrapper">
                                {this.props.selectedFile === ''
                                    ? <span></span>
                                    : <span className="fileName">{this.props.selectedFile}</span>}

                                <button className="btn">
                                    <i className="fas fa-file-upload"></i>Select file
                                </button>
                                <input
                                    ref='fileInput'
                                    type="file"
                                    name="myfile"
                                    accept='.csv'
                                    onClick={this.returnToCurrent}
                                    onChange={(event) => this.props.onSelect(event)} />

                            </div>
                            <div className="credit-posting-files-refresh">
                                <a
                                    className="refresh-anchor"
                                    title="Refresh files and their status"
                                    onClick={this.handleRefreshFiles}>{REFRESH_IMAGE}</a>
                            </div>

                        </div>
                        <Loader
                            show={this.props.isLoading}
                            message={LOADER}
                            backgroundStyle={{
                                backgroundColor: 'white'
                            }}>
                            <div className="table-responsive">
                                {this.props.files.length === 0
                                    ? <h3>{commonConstants.NO_RECORDS_MESSAGE}</h3>
                                    : <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <FileList files={this.props.files} />
                                        </tbody>
                                    </table>}
                            </div>
                        </Loader>

                        <div className="message-info">
                            {invoiceConstants.UPLOAD_PAGE_MESSAGE}
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
export default FileInput;
import React from 'react';
import Header from '.././headers/header';
import {SERVER_ERROR_MESSAGE_TITLE, SERVER_ERROR_MESSAGE_HELPER} from '../../../constants/constants';
import {Link} from "react-router-dom";

class ServerErrorComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="layout-container">
                    <Header/>
                    <main className="container">
                        <div className="login-form error-404 box-shadow text-center">
                            <div className="error-404-message-title">{SERVER_ERROR_MESSAGE_TITLE}</div>
                            <h3>{SERVER_ERROR_MESSAGE_HELPER}</h3>
                            <h5>
                                <Link className="go-home-404" to="/home">HOME</Link>
                            </h5>
                        </div>
                    </main>
                </div>
            </React.Fragment>
        )
    }
}
export default ServerErrorComponent;
let constants = () => {

    const UPLOAD_PAGE_MESSAGE = '*View all the files processed on the selected date (upto 45 days)';
    const EMAIL_TEMPLATES_MESSAGE = '*View agreement groups mapped to the specifc templates of HubSpot'
    const RESULT_PAGE_MESSAGE = {
        DIGISIGN_SUCCESS_INFO: '* View all the agreements for which email was successfully sent',
        DIGISIGN_FAILURE_INFO: '* View all the agreements for which email was not sent',
        DIGISIGN_INPROCESS_INFO: '* View all the agreements marked to be sent in the next iteration of email broadcast',
        DIGISIGN_EMAILSENT_INFO: '* Emails are sent at a frequency of 30 minutes'
    };

    const DIGI_SIGN_URL = {
        FETCH_DIGISIGN_RESULT: '/digitalsignature/agreements',
        FETCH_DIGISIGN_TEMPLATES: '/digitalsignature/emailtemplates',
        FETCH_DIGISIGN_FILES: '/digitalsignature/extractfiles',
        SEND_DIGISIGN_EMAILS: '/digitalsignature/sendemails',
        EXPORT_DIGISIGN_RESULT: '/digitalsignature/exportcsv'
    }

    const EMAIL_SEND_STATUS ={
        NONE : 0,
        SUCCESS: 1,
        FAILED: 2
    }

    return {
        RESULT_PAGE_MESSAGE: RESULT_PAGE_MESSAGE,
        UPLOAD_PAGE_MESSAGE: UPLOAD_PAGE_MESSAGE,
        DIGI_SIGN_URL: DIGI_SIGN_URL,
        EMAIL_SEND_STATUS : EMAIL_SEND_STATUS,
        EMAIL_TEMPLATES_MESSAGE: EMAIL_TEMPLATES_MESSAGE
    }
}

module.exports = constants();

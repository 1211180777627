import * as types from '../../constants/invoicecreation/actiontypes';

export function getAllInvoiceFiles(date) {
    return { date: date, type: types.FETCH_INVOICE_FILES };
}

export function getAllInvoiceFilesFailure() {
    return { type: types.FETCH_INVOICE_FILES_FAILURE };
}

export function getAllInvoiceFilesSuccess(files) {
    return { type: types.FETCH_INVOICE_FILES_SUCCESS, files: files };
}

export function getInvoiceResult(fromDate, toDate, recordsType, offset, memberId) {

    return {
        type: types.FETCH_INVOICE_RESULT,
        searchInfo: {
            fromDate: fromDate,
            toDate: toDate,
            recordsType: recordsType,
            offset: offset,
            memberId: memberId
        }
    };
}

export function getInvoiceResultSuccess(records) {
    return {type: types.FETCH_INVOICE_RESULT_SUCCESS, invoiceResult: records};
}

export function getInvoiceResultFailure() {
    return {type: types.FETCH_INVOICE_RESULT_FAILURE};
}

export function exportInvoiceResultCsv(fromDate, toDate, recordsType, memberId) {
    return {
        type: types.EXPORT_INVOICE_RESULT_CSV,
        searchInfo: {
            fromDate: fromDate,
            toDate: toDate,
            recordsType: recordsType,
            memberId: memberId
        }
    };
}

export function exportInvoiceResultCsvSuccess(recordsInfo) {
    
    return {type: types.EXPORT_INVOICE_RESULT_CSV_SUCCESS, exportInvoiceResult: recordsInfo};
}

export function exportInvoiceResultCsvFailure() {
    
    return {type: types.EXPORT_INVOICE_RESULT_CSV_FAILURE};
}



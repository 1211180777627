import * as path from "../constants/constants.js";
import httpRequests from "../commonUtils/httpRequestHandler";
import * as commonConstants from "../constants/constants.js";

const creditConstants = require('../constants/creditposting/constants');
const CREDIT_POSTING_URL = creditConstants.CREDIT_POSTING_URL;

export const fetchCreditFiles = (data) => {
    let url = process.env.REACT_APP_API_URL + path.FETCH_FILES_CP_URL + `/?date=${data}`;
    return httpRequests.get(url);
}

export const fetchCreditResult = (searchInfo) => {

    let fromDate = searchInfo.searchInfo.fromDate;
    let toDate = searchInfo.searchInfo.toDate;
    let recordsType = Number(searchInfo.searchInfo.recordsType);
    let offset = searchInfo.searchInfo.offset;
    let memberId = searchInfo.searchInfo.memberId;
    let recordsTypeParam;
    let queryString = '';

    switch (recordsType) {
        case commonConstants.SUCCESS_RECORDS_TYPE:
            recordsTypeParam = 'success';
            break;
        case commonConstants.FAILURE_RECORDS_TYPE:
            recordsTypeParam = 'failure';
            break;
        case commonConstants.INPROCESS_RECORDS_TYPE:
            recordsTypeParam = 'inprocess';
            break;
        default:
            recordsTypeParam = 'success';
    }

    queryString = queryString + `fromDate=${fromDate}`;
    queryString = queryString + `&toDate=${toDate}`;
    queryString = queryString + `&recordsType=${recordsTypeParam}`;
    queryString = queryString + `&offset=${offset}`;

    if (memberId !== '') {

        queryString = queryString + `&memberId=${memberId}`;
    }

    let url = process.env.REACT_APP_API_URL + CREDIT_POSTING_URL.FETCH_CREDIT_RESULT + '/?' + queryString;

    return httpRequests.get(url);
}

export const exportCreditResult = (searchInfo) => {

    let fromDate = searchInfo.searchInfo.fromDate;
    let toDate = searchInfo.searchInfo.toDate;
    let recordsType = Number(searchInfo.searchInfo.recordsType);
    let memberId = searchInfo.searchInfo.memberId;
    let filename = '';
    let recordsTypeParam;
    let queryString = '';

    switch (recordsType) {
        case commonConstants.SUCCESS_RECORDS_TYPE:
            recordsTypeParam = 'success';
            filename = 'Success_Credits';
            break;
        case commonConstants.FAILURE_RECORDS_TYPE:
            recordsTypeParam = 'failure';
            filename = 'Failed_Credits'
            break;
        case commonConstants.INPROCESS_RECORDS_TYPE:
            recordsTypeParam = 'inprocess';
            filename = 'Inprocess_Credits';
            break;
        default:
            recordsTypeParam = 'success';
            filename = 'Success_Credits';
    }

    queryString = queryString + `fromDate=${fromDate}`;
    queryString = queryString + `&toDate=${toDate}`;
    queryString = queryString + `&recordsType=${recordsTypeParam}`;

    if (memberId !== '') {
        queryString = queryString + `&memberId=${memberId}`;
        filename = filename + '_' + memberId;
    }

    filename = toDate === fromDate ? filename + '_' + toDate : filename + '_' + fromDate + '_To_' + toDate;

    let url = process.env.REACT_APP_API_URL + CREDIT_POSTING_URL.EXPORT_CREDIT_RESULT + '/?' + queryString;

    return httpRequests.fileDownload(url, filename);
}

import React from 'react';

export const USERNAME_EMPTY_ERROR = 'Username cannot be empty';
export const PASSWORD_EMPTY_ERROR = 'Password cannot be empty';

export const LOCAL_URL = 'http://localhost:3003';
export const DEV_URL = 'http://digitalsignature-dev.middlewarecrunch.com:5003';
export const PROD_URL = 'https://dashboard.crunchauth.com:9003';

export const LOADER = <span><img
    className="imageLoader"
    src={require('../static/img/loader.gif')}
    alt="loading.." /></span>;

export const SERVER_ERROR = 'SERVER ERROR';
export const SESSION_EXPIRED = 'SESSION EXPIRED';
export const LOGIN_REQUIRED = 'LOGIN REQUIRED';

export const REGEX_FILENAME = (/Cancel_[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])_(2[0-3]|[01][0-9])-[0-5][0-9].csv/)

export const AUTHENTICATION_FAILED = '!! AUTHENTICATION FAILED !!';

//KEY must be 16 characters long
export const KEY = 'CRUNCHKEYSAVINGCREDENTIALS';

export const UPLOAD_DATA_AC_URL = '/agreementCancellation/uploadCsv'
export const FETCH_FILES_AC_URL = '/agreementCancellation/filesofDate'
export const CHECK_FILE_IN_DB = '/agreementCancellation/checkFileInDb'

export const SUCCESS_RECORDS_TYPE = 0;
export const FAILURE_RECORDS_TYPE = 1;
export const INPROCESS_RECORDS_TYPE = 2;

export const RECORDS_LIMIT_PER_PAGE = 30;

export const PAGE_INFO_SUCCESS_AGREEMENTS_CANCEL_RECORDS_MESSAGE = '* View all the successful records processed on the selected date';
export const PAGE_INFO_FAILURE_AGREEMENTS_CANCEL_RECORDS_MESSAGE = '* View all the failed records on the selected date';
export const PAGE_INFO_INPROCESS_AGREEMENTS_CANCEL_RECORDS_MESSAGE = '* View all the In Process records on the selected date';

export const NO_RECORDS_MESSAGE = 'No records found, please choose another date';

export const ERROR_404_MESSAGE_TITLE = '404 ERROR';
export const ERROR_404_MESSAGE_HELPER = 'Sorry! The page you are looking for cannot be found';

export const UPLOAD_DATA_CP_URL = '/creditposting/uploadCsv';
export const FETCH_FILES_CP_URL = '/creditposting/filesofDate';
export const CHECK_FILE_IN_DB_CP = '/creditposting/checkFileInDb';
export const MAKE_ENTRY_FOR_THE_FILE = '/creditposting/makeEntryForTheFile';
export const UPDATE_ENTRY_FOR_THE_FILE = '/creditposting/updateStatusForTheFile';
export const CHECK_DUPLICATE_FILENAME = '/creditposting/checkDuplicateFilename';


export const REFRESH_IMAGE = <span><img
    className="refresh-image"
    src={require('../static/img/refresh.png')}
    alt="Refresh" /></span>;
export const SEARCH_IMAGE = <img
    className="search-image"
    src={require('../static/img/search.jpg')}
    alt="Search" />;
export const RESET_SEARCH_IMAGE = <img
    className="reset_search-image"
    src={require('../static/img/reset.jpg')}
    alt="Reset" />;

export const NO_RECORDS_SEARCH_CRITERIA_MESSAGE = 'No records found for the selection criteria';

export const CSV_DATE_FORMAT_ERROR_CODE = 11;

export const CREDIT_RECORDS_LIMIT_PER_PAGE = 50;
export const INVOICE_RECORDS_LIMIT_PER_PAGE = 50;
export const DIGISIGN_RECORDS_LIMIT_PER_PAGE = 50;
export const SERVER_ERROR_MESSAGE_TITLE = 'Sorry! Server Error Occurred';
export const SERVER_ERROR_MESSAGE_HELPER = 'Please contact Crunch Administrator';

export const SERVER_UNREACHABLE_MESSAGE_TITLE = 'Sorry! Server is not reachable';
export const SERVER_UNREACHABLE_MESSAGE_HELPER = 'Please try after some time or contact Crunch Administrator';

export const FileStatusEnum = {
    UPLOADING: 1,
    UPLOADED: 2,
    IN_PROCESS: 3,
    PROCESSED: 4,
    PROCESSED_WITH_ERRORS: 5,
    UPLOADING_ERROR: 6,
    UNKNOWN_ERROR: 7,
    HEADER_INCORRECT: 8,
    EMPTY_FILE: 9,
    properties: {
        1: { name: "Uploading", value: 1 },
        2: { name: "Uploaded", value: 2 },
        3: { name: "In Process", value: 3 },
        4: { name: "Processed", value: 4 },
        5: { name: "Processed with errors", value: 5 },
        6: { name: "Uploading error", value: 6 },
        7: { name: "Unknown error", value: 7 },
        8: { name: "File header incorrect", value: 8 },
        9: { name: "Empty File", value: 9 }
    }
};
export const DigiSignFileStatusEnum = {
    properties: {
        0: { name: "Copy Error", value: 0 },
        1: { name: "Copied", value: 1 },
        2: { name: "In Process", value: 2 },
        3: { name: "Processed", value: 3 },
        4: { name: "Error", value: 4 }
    }
};

export const FILENAME_DUPLICATE_MESSAGE = 'Sorry! A file with the same name already exists in the system';

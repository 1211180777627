import React from 'react';
import * as commonConstants from '../../../constants/constants';
const digiSignConstants = require('../../../constants/digitalsignature/constants');
const PAGE_MESSAGE_CONSTANTS = digiSignConstants.RESULT_PAGE_MESSAGE;

class DigiSignResultPageMessage extends React.Component {

    render() {

        let pageMessage;
        if (Number(this.props.recordsType) === commonConstants.SUCCESS_RECORDS_TYPE) {
            pageMessage = PAGE_MESSAGE_CONSTANTS.DIGISIGN_SUCCESS_INFO;
        } else if (Number(this.props.recordsType) === commonConstants.FAILURE_RECORDS_TYPE) {
            pageMessage = PAGE_MESSAGE_CONSTANTS.DIGISIGN_FAILURE_INFO;

        } else if (Number(this.props.recordsType) === commonConstants.INPROCESS_RECORDS_TYPE) {
            pageMessage = PAGE_MESSAGE_CONSTANTS.DIGISIGN_INPROCESS_INFO;
        } else {
            pageMessage = '';
        }

        return (
            <React.Fragment>
                <div className="message-info">
                    {pageMessage}
                </div>
            </React.Fragment>
        );
    }

}

export default DigiSignResultPageMessage;
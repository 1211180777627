export const FETCH_CREDIT_FILES = 'FETCH_CREDITS_FILES';
export const FETCH_CREDIT_FILES_SUCCESS = 'FETCH_CREDITS_FILES_SUCCESS';
export const FETCH_CREDIT_FILES_FAILURE = 'FETCH_CREDITS_FILES_FAILURE';

export const FETCH_CREDITS_RESULT = 'FETCH_CREDITS_RESULT';
export const FETCH_CREDITS_RESULT_SUCCESS = 'FETCH_CREDITS_RESULT_SUCCESS';
export const FETCH_CREDITS_RESULT_FAILURE = 'FETCH_CREDITS_RESULT_FAILURE';

export const EXPORT_CREDITS_RESULT_CSV = 'EXPORT_CREDITS_RESULT_CSV';
export const EXPORT_CREDITS_RESULT_CSV_SUCCESS = 'EXPORT_CREDITS_RESULT_CSV_SUCCESS';
export const EXPORT_CREDITS_RESULT_CSV_FAILURE = 'EXPORT_CREDITS_RESULT_CSV_FAILURE';

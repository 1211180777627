import React from 'react';
import Loader from 'react-loader-advanced';
import { LOADER, REFRESH_IMAGE } from '../../../constants/constants';
import '../../../static/css/App.css';
import '../../../static/css/index.css';
import '../../../static/css/agreementCancellationResults.css';
import * as commonConstants from '../../../constants/constants';
import { get45DaysBeforeDate, getCurrentDate } from '../../../commonUtils/utility';
import digiSignConstants from '../../../constants/digitalsignature/constants';
import { getFileStatusNameDigiSign } from '../../../commonUtils/utility';

class FileList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: getCurrentDate(),
            selectedDate: getCurrentDate()
        }

        this.handleKeyDownDate = this
            .handleKeyDownDate
            .bind(this);
        this.handleSelectedDate = this
            .handleSelectedDate
            .bind(this);
        this.handleRefreshFiles = this
            .handleRefreshFiles
            .bind(this);
        this.returnToCurrent = this
            .returnToCurrent
            .bind(this);
    }

    returnToCurrent() {
        let newDate = getCurrentDate();
        this.setState({ selectedDate: newDate });
        this.props.handleSelectedDate(newDate);
        this.refs.fileInput.value = null;
    }

    handleKeyDownDate(e) {
        e.preventDefault();
    }

    handleSelectedDate(event) {
        let newDate = event.target.value;

        if (!newDate) {
            newDate = getCurrentDate();
        }
        this.setState({ selectedDate: newDate });

        this
            .props
            .handleSelectedDate(newDate);

    }

    handleRefreshFiles() {
        this
            .props
            .handleRefreshFiles(this.state.selectedDate);
    }

    render() {
        return (
            <React.Fragment>
                <main className="container">
                    <h1 className="page-heading">Digital Signature File List</h1>
                    <div className="file-upload-list white-bg box-shadow">
                        <div className="table-controls clearfix">
                            <div className="datepicker-control">
                                <input
                                    onKeyDown={this.handleKeyDownDate}
                                    onChange={this.handleSelectedDate}
                                    type="date"
                                    id="datepicker"
                                    className="form-control"
                                    value={this.state.selectedDate}
                                    max={this.state.currentDate}
                                    min={get45DaysBeforeDate(new Date())} />
                            </div>
                            <div className="credit-posting-files-refresh">
                                <a
                                    className="refresh-anchor"
                                    title="Refresh files and their status"
                                    onClick={this.handleRefreshFiles}>{REFRESH_IMAGE}</a>
                            </div>

                        </div>
                        <Loader
                            show={this.props.isLoading}
                            message={LOADER}
                            backgroundStyle={{
                                backgroundColor: 'white'
                            }}>
                            <div className="table-responsive">
                                {this.props.files.length === 0
                                    ? <h3>{commonConstants.NO_RECORDS_MESSAGE}</h3>
                                    : <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.files && this.props.files.map((file, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{file.FileName}</td>
                                                        <td>{getFileStatusNameDigiSign(file.ProcessStatus)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>}
                            </div>
                        </Loader>

                        <div className="message-info">
                            {digiSignConstants.UPLOAD_PAGE_MESSAGE}
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
export default FileList;
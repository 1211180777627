import React from 'react';
import * as commonConstants from '../../../../constants/constants';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import moment from 'moment';
import momenttimezeone from 'moment-timezone';

class SentEmailResultTable extends React.Component {

    render() {
        let recordElementArray = [];
        let componentData;
        if (this.props.records) {
            let successRecord;
            this
                .props
                .records
                .records
                .map((record, index) => {
                    successRecord = <tr key={index}>
                        <td>{record.CsvFileName}</td>
                        {/* <td title={'Processed on - ' + moment(record.CreatedAt).format('MMMM DD, YYYY')}>{record.Name}</td> */}
                        <td>{record.MemberId}</td>
                        <td>{record.MemberAgreementId}</td>
                        <td>{record.FirstName+' '+record.LastName}</td>
                        <td>{record.EmailId}</td>
                        <td>{record.ClubId}</td> 
                        <td>{record.IsReminder ? 'Yes' : 'No'}</td>
                        <td>{record.ReminderCount}</td>
                        <td>{record.IsSigned ? 'Yes' : 'No'}</td>
                        <td>{record.FirstEmailSentAt > 0 ?momenttimezeone.unix(record.FirstEmailSentAt).tz('America/New_York').format('MMMM DD, YYYY'):'N/A'}</td>
                        <td>{record.LastEmailSentAt > 0 ?momenttimezeone.unix(record.LastEmailSentAt).tz('America/New_York').format('MMMM DD, YYYY'):'N/A'}</td>
                        <td><input type="checkbox" disabled={record.IsSigned} data-recordid={record.Id} checked={this.props.checkedRecords.indexOf(record.Id) > -1} onChange={this.props.handleCheck}></input></td>
                    </tr>;
                    return recordElementArray.push(successRecord);
                });
        }


        let pagination;
        
        if (this.props.records) {
            pagination = <nav aria-label="Page navigation example">
                <Pagination defaultPageSize={commonConstants.DIGISIGN_RECORDS_LIMIT_PER_PAGE} pageSize={commonConstants.DIGISIGN_RECORDS_LIMIT_PER_PAGE} current={this.props.currentPaginateNumber + 1} defaultCurrent={1} //change it to constant
                    onChange={this.props.handlePagination} total={this.props.records.recordsCount} showTitle={false} hideOnSinglePage={true} />
            </nav>;

        }

        let recordsTable = (
            <React.Fragment>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Member ID</th>
                                <th>Agreement ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Club Id</th> 
                                <th>Reminder Sent</th>
                                <th>Reminder Count</th>
                                <th>Is Signed</th>
                                <th>First Email Sent</th>
                                <th>Last Email Sent</th>
                                <th>Send Reminder <input type="checkbox"
                                    data-records={JSON.stringify(this.props.records.records.filter((ele) => !ele.IsSigned).map((ele) => ele.Id))}
                                    onClick={this.props.handleCheckAll} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {recordElementArray}
                        </tbody>
                    </table>
                </div>
                {this.props.records && pagination}
            </React.Fragment>
        );

        let emptyRecordsMessage = <h3>{commonConstants.NO_RECORDS_MESSAGE}</h3>;

        let emptySearchRecordsMessage = <h3>{commonConstants.NO_RECORDS_SEARCH_CRITERIA_MESSAGE}</h3>;

        if (recordElementArray.length > 0) {
            componentData = recordsTable;
        } else if (this.props.isSearchCriteria && this.props.memberId.length >= 0) {
            componentData = emptySearchRecordsMessage;
        } else {
            componentData = emptyRecordsMessage;
        }
        return (
            <React.Fragment>

                {!this.props.isLoading && componentData}

            </React.Fragment>
        );
    }
    // handleCheckAllComponents (event){
    //     
    //     // this.recordElementArray
    //     // this.props. (event);
    // }
}

export default SentEmailResultTable;
import React from 'react';
import HeaderCP from '../../common/headers/headerCP';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import FileInput from './fileinput';
import * as commonConstants from '../../../constants/constants';
import axios from 'axios';
import Loader from 'react-loader-advanced';
import { LOADER } from '../../../constants/constants';
import { resolve } from 'dns';

const creditsConstant = require('../../../constants/creditposting/constants');


class CreditFileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: '',
            newFileName: '',
            newFileState: '',
            allFiles: [],
            isLoading: false,
            isLoadingFile: false,
            fileAddedId: ''
        };
        this.fileName = '';
        this.filereader = '';
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.handleUploadToServer = this.handleUploadToServer.bind(this);
        this.checkValidFileName = this.checkValidFileName.bind(this);
        this.getCurrentDate = this.getCurrentDate.bind(this);
        this.handleSelectedDate = this.handleSelectedDate.bind(this);
        this.makeEntryForTheFile = this.makeEntryForTheFile.bind(this);
        this.updateStatusForTheFile = this.updateStatusForTheFile.bind(this);
        this.checkDuplicateFileName = this.checkDuplicateFileName.bind(this);
        this.handleRefreshFiles = this.handleRefreshFiles.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isLoading: false
        })
        this.setState({
            allFiles: nextProps.files
        });

    }

    componentWillMount() {
        this.props.checkIsLoggedIn();
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.props.getAllCreditFiles(this.getCurrentDate());
    }
    handleRefreshFiles(date) {
        this.setState({ isLoading: true });
        this.props.getAllCreditFiles(date);
    }


    handleSelectedDate(date) {
        this.setState({
            isLoading: true
        });
        this.props.getAllCreditFiles(date);
    }

    checkValidFileName(fileName) {
        return new Promise((resolve, reject) => {
            if (fileName) {
                let regex = creditsConstant.REGEX_FILENAME;
                let isValid = regex.test(fileName);
                if (!isValid) {
                    let obj = { isValid: false };
                    resolve(obj);
                }
                else {

                    this.checkDuplicateFileName(fileName).then(res => {

                        let obj = { isDuplicate: res.isDuplicate.data, isValid: true };
                        resolve(obj);
                    })
                }
            }
        })


    }

    getCurrentDate() {

        let dateObj = new Date();

        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let date = dateObj.getDate();

        let formattedMonth = (month + 1) < 10 ? '0' + (month + 1) : (month + 1);
        let formattedDate = date < 10 ? '0' + date : date;

        let currentDate = year + '-' + formattedMonth + '-' + formattedDate;

        return currentDate;
    }

    checkFileInDb() {
        let filename = this.state.selectedFile.filename;
        const data = new FormData();
        data.append('filename', filename);
        let token = localStorage.getItem('token');
        token = 'Token ' + token;
        let url = process.env.REACT_APP_API_URL + commonConstants.CHECK_FILE_IN_DB_CP;
        return axios.post(url, data, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                if (response.data === true) {
                    clearInterval(this.polling);
                    let files = this.state.allFiles;
                    if (files[0].Status === 'Processed') {
                        files[0] = { CsvFileName: this.state.selectedFile.filename, Status: 'Processed' };
                    }
                    else
                        files[0] = { CsvFileName: this.state.selectedFile.filename, Status: 'In Process' };
                    this.setState({
                        allFiles: files,
                        isLoadingFile: false
                    });

                }
            }).catch(err => {
                console.log(err);
            });
    }

    makeEntryForTheFile(fileName, status) {
        let data = {
            fileName: fileName,
            status: status
        };
        let token = localStorage.getItem('token');
        token = 'Token ' + token;
        let url = process.env.REACT_APP_API_URL + commonConstants.MAKE_ENTRY_FOR_THE_FILE;
        return axios.post(url, data, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {

                this.setState({
                    fileAddedId: response.data.Id
                })
            }).catch(err => {
                console.log(err);
            });
    }

    updateStatusForTheFile(id, status) {
        let data = {
            id: id,
            status: status
        };
        let token = localStorage.getItem('token');
        token = 'Token ' + token;
        let url = process.env.REACT_APP_API_URL + commonConstants.UPDATE_ENTRY_FOR_THE_FILE;
        return axios.post(url, data, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                console.log(response);
            }).catch(err => {
                console.log(err);
            });
    }



    handleUpload() {
        let file = this.state.selectedFile.file;
        this.filereader = new FileReader();
        this.filereader.onloadend = () => this.handleFileRead();
        this.filereader.readAsText(file);
    }

    handleFileRead() {
        let allFiles1 = this.props.files;
        allFiles1.unshift({ CsvFileName: this.state.selectedFile.filename, Status: 'Uploading' });
        this.setState({
            newFileName: '',
            allFiles: allFiles1,
            isLoadingFile: false
        });
        //setting new file stae to uploading
        this.handleUploadToServer();
    }

    async handleUploadToServer() {
        let files = this.state.allFiles;
        files[0] = { CsvFileName: this.state.selectedFile.filename, Status: 'In Process' };
        this.setState({
            allFiles: files
        })
        let token = sessionStorage.getItem('token');
        token = 'Token ' + token;
        let file = this.state.selectedFile.file;
        let filename = this.state.selectedFile.filename;
        const data = new FormData();
        data.append('file', file);
        data.append('filename', filename);
        let url = process.env.REACT_APP_API_URL + commonConstants.UPLOAD_DATA_CP_URL;
        return axios.post(url, data, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                this.props.getAllCreditFiles(this.getCurrentDate());
            }).catch(err => {
                console.log(err);
                this.props.getAllCreditFiles(this.getCurrentDate());
            });
    }

    notifyErrorInvalid = () => {
        toast.error(creditsConstant.FILENAME_INVALID_MESSAGE, {
            position: toast.POSITION.TOP_CENTER
        });
    }

    notifyErrorDuplicateFileName = () => {
        toast.error(creditsConstant.FILENAME_DUPLICATE_MESSAGE, {
            position: toast.POSITION.TOP_CENTER
        });
    }

    handleSelectedFile(fileSelected) {
        let selectedFile = fileSelected.currentTarget.files[0];
        if (selectedFile) {
            this.checkValidFileName(selectedFile.name).then(res => {

                if (res.isValid && !res.isDuplicate) {
                    let file = {
                        file: selectedFile,
                        filename: selectedFile.name,
                        isUploading: false,
                        isUploaded: false,
                        errorInUploading: false,
                        isProcessing: false,
                        isProcessed: false,
                        errorInProcessing: false,
                    };
                    this.setState({ selectedFile: file, newFileName: selectedFile.name });
                }
                else if (res.isValid && res.isDuplicate) {
                    this.notifyErrorDuplicateFileName();
                    this.setState({ selectedFile: '', newFileName: '' });
                    this.fileName = "";
                }
                else {
                    this.notifyErrorInvalid();
                    this.setState({ selectedFile: '', newFileName: '' });
                    this.fileName = "";
                }
            })
        }
    }

    checkDuplicateFileName(filename) {
        return new Promise((resolve, reject) => {
            let data = {
                fileName: filename
            };
            let token = localStorage.getItem('token');
            token = 'Token ' + token;
            let url = process.env.REACT_APP_API_URL + commonConstants.CHECK_DUPLICATE_FILENAME;
            return axios.post(url, data, {
                headers: {
                    'Authorization': token
                }
            })
                .then((response) => {
                    let obj = { isDuplicate: response };
                    resolve(obj)
                }).catch(err => {
                    console.log(err);
                });
        })

    }


    render() {

        if (this.props.isLoggedIn === '') {
            return <div>{LOADER}</div>

        }
        if (!this.props.isLoggedIn) {
            return <Redirect to='/login' />;
        }
        return (
            <div className="layout-container">
                <Loader show={this.state.isLoadingFile} message={LOADER} backgroundStyle={{ opacity: 0.3, backgroundColor: '#fafafa94' }} >
                    <HeaderCP isLoading={this.state.isLoadingFile} logout={this.props.logout} />
                    <FileInput
                        stopLoader={this.stopLoader}
                        isLoading={this.state.isLoading}
                        handleSelectedDate={this.handleSelectedDate}
                        files={this.state.allFiles}
                        selectedFile={this.state.newFileName}
                        onSelect={(selectedFile) => this.handleSelectedFile(selectedFile)}
                        onUpload={this.handleUpload}
                        handleRefreshFiles={(selectedDate) => this.handleRefreshFiles(selectedDate)}
                    />
                    <ToastContainer autoClose={2000} />
                </Loader>
            </div>
        );
    }

}

export default CreditFileUpload;
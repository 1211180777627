import React from 'react';
    
class FileList extends React.Component {

    render() {
        return (
           <React.Fragment>
               {this.props.files.map((file,index)=>{
                   return(
                    <tr key={index}>
                        <td>{file.CsvFileName}</td>
                        <td>{file.status}</td>
                    </tr>
                   )
               })}
               
            </React.Fragment>     
            )
        }
    }
export default FileList;
import * as actionTypes from "../constants/actionTypes.js";

const initialState = {
    files:[],
};

export const getFilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FILES_SUCCESS:
           return  Object.assign({},...[state],{files:action.files});
        default:
           return state;
    }
};


import React from 'react';
import FileList from './filelist';
import Loader from 'react-loader-advanced';
import { LOADER, REFRESH_IMAGE } from '../../../constants/constants';
import '../../../static/css/App.css';
import '../../../static/css/index.css';
import '../../../static/css/agreementCancellationResults.css';
import * as commonConstants from '../../../constants/constants';
import '../../../static/css/creditposting/credit-posting-files-upload.css';

const creditsConstants = require('../../../constants/creditposting/constants');

class FileInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: this.getCurrentDate(),
            selectedDate: this.getCurrentDate()
        }

        this.getCurrentDate = this
            .getCurrentDate
            .bind(this);
        this.get45DaysBeforeDate = this
            .get45DaysBeforeDate
            .bind(this);
        this.handleKeyDownDate = this
            .handleKeyDownDate
            .bind(this);
        this.handleSelectedDate = this
            .handleSelectedDate
            .bind(this);
        this.returnToCurrent = this
            .returnToCurrent
            .bind(this);
        this.handleRefreshFiles = this
            .handleRefreshFiles
            .bind(this);
    }

    returnToCurrent() {
        let newDate = this.getCurrentDate();
        this.setState({ selectedDate: newDate });
        this
            .props
            .handleSelectedDate(newDate);
        this.refs.fileInput.value = null;
    }

    /**
     *
     * @param {Date} dateObject
     * getting the date 45 before the current
     */
    get45DaysBeforeDate(dateObject) {
        let beforeDays = 45;
        dateObject.setDate(dateObject.getDate() - beforeDays);
        return dateObject
            .toISOString()
            .substring(0, 10);

    }

    handleKeyDownDate(e) {
        e.preventDefault();
    }

    handleSelectedDate(event) {
        let newDate = event.target.value;

        if (newDate === '') {
            newDate = this.getCurrentDate();
        }
        this.setState({ selectedDate: newDate });

        this
            .props
            .handleSelectedDate(newDate);

    }

    //getting date formatted as accepted by the date picker
    getCurrentDate = () => {

        let dateObj = new Date();

        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let date = dateObj.getDate();

        let formattedMonth = (month + 1) < 10
            ? '0' + (month + 1)
            : (month + 1);
        let formattedDate = date < 10
            ? '0' + date
            : date;

        let currentDate = year + '-' + formattedMonth + '-' + formattedDate;

        return currentDate;
    }

    handleRefreshFiles() {
        this
            .props
            .handleRefreshFiles(this.state.selectedDate);
    }


    render() {
        return (
            <React.Fragment>
                <main className="container">
                    <h1 className="page-heading">Credit Posting File Upload</h1>
                    <div className="file-upload-list white-bg box-shadow">
                        <div className="table-controls clearfix">
                            <div className="datepicker-control">
                                <input
                                    onKeyDown={this.handleKeyDownDate}
                                    onChange={this.handleSelectedDate}
                                    type="date"
                                    id="datepicker"
                                    className="form-control"
                                    value={this.state.selectedDate}
                                    max={this.state.currentDate}
                                    min={this.get45DaysBeforeDate(new Date())} />
                            </div>

                            <div className="upload-btn-wrapper">
                                {this.props.selectedFile === ""
                                    ? <button className="btnUpload" disabled>
                                        Upload
                                        </button>
                                    : <button className="btnUpload" onClick={(event) => this.props.onUpload(event)}>
                                        Upload
                                    </button>}
                            </div>
                            <div className="browse-btn-wrapper">
                                {this.props.selectedFile === ''
                                    ? <span></span>
                                    : <span className="fileName">{this.props.selectedFile}</span>}

                                <button className="btn">
                                    <i className="fas fa-file-upload"></i>Select file
                                </button>
                                <input
                                    ref='fileInput'
                                    type="file"
                                    name="myfile"
                                    accept='.csv'
                                    onClick={this.returnToCurrent}
                                    onChange={(event) => this.props.onSelect(event)} />

                            </div>
                            <div className="credit-posting-files-refresh">
                                <a
                                    className="refresh-anchor"
                                    title="Refresh files and their status"
                                    onClick={this.handleRefreshFiles}>{REFRESH_IMAGE}</a>
                            </div>

                        </div>
                        <Loader
                            show={this.props.isLoading}
                            message={LOADER}
                            backgroundStyle={{
                                backgroundColor: 'white'
                            }}>
                            <div className="table-responsive">
                                {this.props.files.length === 0
                                    ? <h3>{commonConstants.NO_RECORDS_MESSAGE}</h3>
                                    : <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <FileList files={this.props.files} />
                                        </tbody>
                                    </table>}
                            </div>
                        </Loader>

                        <div className="message-info">
                            {creditsConstants.UPLOAD_PAGE_MESSAGE}
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
export default FileInput;
import React from 'react';
import { getFileStatusName } from '../../../commonUtils/utility';

class FileList extends React.Component {

    render() {
        return (
            <React.Fragment>
                {this.props.files.map((file, index) => {
                    return (
                        <tr key={index}>
                            <td>{file.Name}</td>
                            <td>{getFileStatusName(file.Status)}</td>
                        </tr>
                    )
                })}

            </React.Fragment>
        )
    }
}
export default FileList;
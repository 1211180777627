import DigiSignTemplate from './digiSignTemplates';
import * as loginStateActions from "../../../actions/actions";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as digiSignActions from "../../../actions/digitalsignature/actions";

const mapStateToProps = state => {
    let data = {
        isLoggedIn: state.login.isLoggedIn,
        templates: state.digiSignTemplates,
    };
    return data;
};

const mapDispatchToProps = dispatch => ({
    getAllEmailTemplates: () => dispatch(digiSignActions.getAllDigiSignTemplates()),
    logout: () => dispatch(loginStateActions.logout()),
    checkIsLoggedIn: () => dispatch(loginStateActions.checkIsLoggedIn()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigiSignTemplate));

import * as actionTypes from "../constants/actionTypes.js";

const initialState = {
    isProcessed:false
};

export const storeDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_DATA_SUCCESS:
           return  Object.assign({},...[state],{isProcessed:action.isProcessed});
        default:
           return state;

    }
};


import httpRequests from "./httpRequestHandler";
import * as commonConstants from "../constants/constants.js";
import invoiceConstants from '../constants/invoicecreation/constants';

const API_URL = invoiceConstants.API_URL;

export const fetchInvoiceFiles = (date) => {
    let url = process.env.REACT_APP_API_URL + API_URL.FETCH_FILES + `?date=${date}`;
    return httpRequests.get(url);
}

export const isFilenameDuplicate = (filename) => {
    let url = process.env.REACT_APP_API_URL + API_URL.CHECK_DUPLICATE_FILE_NAME + `/${filename}`;
    return httpRequests.get(url);
}

export const fetchInvoiceResult = (searchInfo) => {

    let fromDate = searchInfo.searchInfo.fromDate;
    let toDate = searchInfo.searchInfo.toDate;
    let recordsType = Number(searchInfo.searchInfo.recordsType);
    let offset = searchInfo.searchInfo.offset;
    let memberId = searchInfo.searchInfo.memberId;
    let recordsTypeParam;
    let queryString = '';

    switch (recordsType) {
        case commonConstants.SUCCESS_RECORDS_TYPE:
            recordsTypeParam = 'success';
            break;
        case commonConstants.FAILURE_RECORDS_TYPE:
            recordsTypeParam = 'failure';
            break;
        case commonConstants.INPROCESS_RECORDS_TYPE:
            recordsTypeParam = 'inprocess';
            break;
        default:
            recordsTypeParam = 'success';
    }

    queryString = queryString + `fromDate=${fromDate}`;
    queryString = queryString + `&toDate=${toDate}`;
    queryString = queryString + `&recordsType=${recordsTypeParam}`;
    queryString = queryString + `&offset=${offset}`;

    if (memberId !== '') {

        queryString = queryString + `&memberId=${memberId}`;
    }

    let url = process.env.REACT_APP_API_URL + API_URL.FETCH_INVOICE_RESULT + '/?' + queryString;

    return httpRequests.get(url);
}

export const exportInvoiceResult = (searchInfo) => {

    let fromDate = searchInfo.searchInfo.fromDate;
    let toDate = searchInfo.searchInfo.toDate;
    let recordsType = Number(searchInfo.searchInfo.recordsType);
    let memberId = searchInfo.searchInfo.memberId;
    let filename = '';
    let recordsTypeParam;
    let queryString = '';

    switch (recordsType) {
        case commonConstants.SUCCESS_RECORDS_TYPE:
            recordsTypeParam = 'success';
            filename = 'Success_Invoice';
            break;
        case commonConstants.FAILURE_RECORDS_TYPE:
            recordsTypeParam = 'failure';
            filename = 'Failed_Invoice'
            break;
        case commonConstants.INPROCESS_RECORDS_TYPE:
            recordsTypeParam = 'inprocess';
            filename = 'Inprocess_Invoice';
            break;
        default:
            recordsTypeParam = 'success';
            filename = 'Success_Invoice';
    }

    queryString = queryString + `fromDate=${fromDate}`;
    queryString = queryString + `&toDate=${toDate}`;
    queryString = queryString + `&recordsType=${recordsTypeParam}`;

    if (memberId !== '') {
        queryString = queryString + `&memberId=${memberId}`;
        filename = filename + '_' + memberId;
    }

    filename = toDate === fromDate ? filename + '_' + toDate : filename + '_' + fromDate + '_To_' + toDate;

    let url = process.env.REACT_APP_API_URL + API_URL.EXPORT_INVOICE_RESULT + '/?' + queryString;

    return httpRequests.fileDownload(url, filename);
}

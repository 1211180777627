import React from 'react';
import Loader from 'react-loader-advanced';
import { LOADER } from '../../../constants/constants';
import { ToastContainer, toast } from 'react-toastify';
import HeaderIC from '../../common/headers/headerIC';
import FileInput from './fileinput';
import { getCurrentDate } from '../../../commonUtils/utility';
import axios from 'axios';
import * as INVOICEAPI from '../../../commonUtils/invoiceApi';
import { notifyErrorMessage } from '../../../commonUtils/utility';
import { FILENAME_DUPLICATE_MESSAGE } from '../../../constants/constants';
const invoiceConstants = require('../../../constants/invoicecreation/constants');
const API_URL = invoiceConstants.API_URL;



class InvoiceFileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: '',
            newFileName: '',
            newFileState: '',
            allFiles: [],
            isLoading: false,
            isLoadingFile: false
        };

        this.handleRefreshFiles = this.handleRefreshFiles.bind(this);
        this.handleSelectedDate = this.handleSelectedDate.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleUploadToServer = this.handleUploadToServer.bind(this);
        this.handleFileRead = this.handleFileRead.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.isFilenameDuplicate = this.isFilenameDuplicate.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isLoading: false
        })
        this.setState({
            allFiles: nextProps.files || []
        });

    }

    componentWillMount() {
        this.props.checkIsLoggedIn();
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.props.getAllInvoiceFiles(getCurrentDate());
    }

    handleRefreshFiles(date) {
        this.setState({ isLoading: true });
        this.props.getAllInvoiceFiles(date);
    }

    handleSelectedDate(date) {
        this.setState({
            isLoading: true
        });
        this.props.getAllInvoiceFiles(date);
    }
    async handleSelectedFile(fileSelected) {

        let selectedFile = fileSelected.currentTarget.files[0];

        if (selectedFile) {

            //check file name convention validation
            let isFilenameValid = await this.isFilenameValid(selectedFile.name);

            if (!isFilenameValid) {
                notifyErrorMessage(invoiceConstants.FILENAME_INVALID_MESSAGE);
                this.setState({ selectedFile: '', newFileName: '' });
                this.fileName = "";
                return;
            }

            //check duplicate file validation
            let isFilenameDuplicate = await this.isFilenameDuplicate(selectedFile.name);

            if (isFilenameDuplicate) {
                notifyErrorMessage(FILENAME_DUPLICATE_MESSAGE);
                this.setState({ selectedFile: '', newFileName: '' });
                this.fileName = "";
                return;
            }

            //need to verify these attributes
            let file = {
                file: selectedFile,
                filename: selectedFile.name,
                isUploading: false,
                isUploaded: false,
                errorInUploading: false,
                isProcessing: false,
                isProcessed: false,
                errorInProcessing: false,
            };
            this.setState({ selectedFile: file, newFileName: selectedFile.name });
        }
    }

    handleUpload() {
        let file = this.state.selectedFile.file;
        this.filereader = new FileReader();
        this.filereader.onloadend = () => this.handleFileRead();
        this.filereader.readAsText(file);
    }

    handleFileRead() {
        let allFiles1 = this.props.files;
        allFiles1.unshift({ Name: this.state.selectedFile.filename, Status: 1 });
        this.setState({
            newFileName: '',
            allFiles: allFiles1,
            isLoadingFile: false
        });

        this.handleUploadToServer();
    }


    async handleUploadToServer() {
        let token = sessionStorage.getItem('token');
        token = 'Token ' + token;
        let file = this.state.selectedFile.file;
        let filename = this.state.selectedFile.filename;
        const data = new FormData();
        data.append('file', file);
        data.append('filename', filename);
        let url = process.env.REACT_APP_API_URL + API_URL.UPLOAD_FILE;
        return axios.post(url, data, {
            headers: {
                'Authorization': token
            }
        })
            .then((response) => {
                this.props.getAllInvoiceFiles(getCurrentDate());
            }).catch(err => {
                console.log(err);
                this.props.getAllInvoiceFiles(getCurrentDate());
            });
    }

    async isFilenameDuplicate(filename) {

        let isDuplicate = await INVOICEAPI.isFilenameDuplicate(filename);

        return isDuplicate;
    }

    isFilenameValid(filename) {
        let regex = invoiceConstants.REGEX_FILENAME;
        let isValid = regex.test(filename);
        return isValid;
    }

    render() {
        return (
            <div className="layout-container">
                <Loader show={this.state.isLoadingFile} message={LOADER} backgroundStyle={{ opacity: 0.3, backgroundColor: '#fafafa94' }} >
                    <HeaderIC isLoading={this.state.isLoadingFile} logout={this.props.logout} />
                    <FileInput
                        isLoading={this.state.isLoading}
                        handleSelectedDate={(selectedDate) => this.handleSelectedDate(selectedDate)}
                        files={this.state.allFiles}
                        selectedFile={this.state.newFileName}
                        onSelect={(selectedFile) => this.handleSelectedFile(selectedFile)}
                        onUpload={this.handleUpload}
                        handleRefreshFiles={(selectedDate) => this.handleRefreshFiles(selectedDate)}
                    />
                    <ToastContainer autoClose={2000} />
                </Loader>
            </div>
        );
    }

}

export default InvoiceFileUpload;
import DigitalSignatureResult from './results';  ///TODO:
import * as loginStateActions from "../../../actions/actions";
import * as digiSignStateActions from "../../../actions/digitalsignature/actions";
import {
    connect
} from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
// TODO: how state is being assigned
const mapStateToProps = state => {
    let data = {
        isLoggedIn: state.login.isLoggedIn,
        digiSignResultRecords: state.digiSignResult.digiSignResultRecords,
        exportDigiSignRecords: state.digiSignResult.digiSignResultExportRecords,
        emailSendStatus: state.digiSignResult.emailSendStatus
    };

    return data;
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(loginStateActions.logout()),
    checkIsLoggedIn: () => dispatch(loginStateActions.checkIsLoggedIn()),
    getDigiSignResult: (fromDate, toDate, recordsType, offset, memberId, clubId) =>
        dispatch(digiSignStateActions.getDigiSignResult(fromDate, toDate, recordsType, offset, memberId,clubId)),
    exportDigiSignResultCsv: (fromDate, toDate, recordsType, memberId,clubId) => dispatch(digiSignStateActions.exportDigiSignResultCsv(fromDate, toDate, recordsType, memberId,clubId)),
    sendDigiSignEmails: (records) => dispatch(digiSignStateActions.sendDigiSignEmails(records))

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalSignatureResult));
import React from 'react';
import * as commonConstants from '../../../../constants/constants';
import CreditSuccessResultTable from './successresulttable';
import CreditFailedResultTable from './failedresulttable';
import CreditInprocessResultTable from './inprocessresulttable';

class CreditResultTable extends React.Component {

    render() {
        return (
            <React.Fragment>
                {Number(this.props.recordsType) === commonConstants.SUCCESS_RECORDS_TYPE
                    ? (<CreditSuccessResultTable
                        isLoading={this.props.isLoading}
                        selectedDate={this.props.selectedDate}
                        currentDate={this.props.currentDate}
                        records={this.props.records}
                        currentPaginateNumber={this.props.currentPaginateNumber}
                        handlePagination={this.props.handlePagination}
                        isSearchCriteria={this.props.isSearchCriteria}
                        memberId={this.props.memberId}/>)
                    : (Number(this.props.recordsType) === commonConstants.FAILURE_RECORDS_TYPE
                        ? <CreditFailedResultTable
                                isLoading={this.props.isLoading}
                                selectedDate={this.props.selectedDate}
                                currentDate={this.props.currentDate}
                                records={this.props.records}
                                currentPaginateNumber={this.props.currentPaginateNumber}
                                handlePagination={this.props.handlePagination}
                                isSearchCriteria={this.props.isSearchCriteria}
                                memberId={this.props.memberId}/>
                        : (Number(this.props.recordsType) === commonConstants.INPROCESS_RECORDS_TYPE
                            ? <CreditInprocessResultTable
                                    isLoading={this.props.isLoading}
                                    selectedDate={this.props.selectedDate}
                                    currentDate={this.props.currentDate}
                                    records={this.props.records}
                                    currentPaginateNumber={this.props.currentPaginateNumber}
                                    handlePagination={this.props.handlePagination}
                                    isSearchCriteria={this.props.isSearchCriteria}
                                    memberId={this.props.memberId}/>
                            : ''))}

            </React.Fragment>

        );
    }
}

export default CreditResultTable;
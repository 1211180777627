import React from 'react'
import HeaderHome from '../common/headers/headerHome';
import ActivityList from './activityList';
import {Redirect} from 'react-router-dom';
export const LOADER = <span><img
    className="imageLoader"
    src="https://loading.io/spinners/spin/lg.ajax-spinner-gif.gif"
    alt="loading.."/></span>;

class Home extends React.Component {

    componentWillMount() {
        this.props.checkIsLoggedIn();
    }

    render() {
        if (this.props.isLoggedIn === '') {
            return <div>{LOADER}</div>

        }
        if (!this.props.isLoggedIn) {
            return <Redirect to='/login'/>;
        }
        return (
            <div className="layout-container">
                <HeaderHome logout={this.props.logout}/>
                <main className="container">
                    <h1 className="page-heading">Main Menu</h1>
                    <ActivityList/>
                </main>
            </div>
        );
    }
}

export default Home;
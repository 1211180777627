import React from 'react';
import ReactDOM from 'react-dom';
import './static/css/index.css';

import { loginReducer } from "./reducers/loginReducer.js";

import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from "redux";
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import * as serviceWorker from './serviceWorker';
import rootSaga from "./saga/sagas";
import { getFilesReducer } from './reducers/getFilesReducer';
import { storeDataReducer } from './reducers/storeDataReducer';
import { agreementsCancellationResultRecordsReducer } from './reducers/agreementsCancellationResultRecordsReducer';
import { getCreditFilesReducer } from './reducers/creditposting/getcreditfilesreducer';
import { creditResultReducer } from './reducers/creditposting/resultrecordsreducer';
import { getInvoiceFilesReducer } from './reducers/invoicecreation/getinvoicefilesreducer';
import { invoiceResultReducer } from './reducers/invoicecreation/resultrecordsreducer';
// import { getInvoiceFilesReducer } from './reducers/digitalsignature/getinvoicefilesreducer';
import { digiSignResultReducer } from './reducers/digitalsignature/resultrecordsreducer';
import { digiSignTemplatesReducer } from './reducers/digitalsignature/templatesreducer';
import { digiSignFilesReducer } from './reducers/digitalsignature/filesreducer';


const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
    login: loginReducer,
    files: getFilesReducer,
    storeData: storeDataReducer,
    acResult: agreementsCancellationResultRecordsReducer,
    creditFiles: getCreditFilesReducer,
    creditResult: creditResultReducer,
    invoiceFiles: getInvoiceFilesReducer,
    invoiceResult: invoiceResultReducer,
    digiSignResult: digiSignResultReducer,
    digiSignTemplates: digiSignTemplatesReducer,
    digiSignFiles: digiSignFilesReducer
});

const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

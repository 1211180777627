import InvoiceResult from './results';
import * as loginStateActions from "../../../actions/actions";
import {
    connect
} from 'react-redux';
import {
    withRouter
} from 'react-router-dom';
import * as invoiceStateActions from "../../../actions/invoicecreation/actions";
const mapStateToProps = state => {
    let data = {
        isLoggedIn: state.login.isLoggedIn,
        invoiceResultRecords: state.invoiceResult.invoiceResultRecords,
        exportInvoiceRecords: state.invoiceResult.invoiceResultExportRecords
    };

    return data;
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(loginStateActions.logout()),
    checkIsLoggedIn: () => dispatch(loginStateActions.checkIsLoggedIn()),
    getInvoiceResult: (fromDate, toDate, recordsType, offset, memberId) =>
        dispatch(invoiceStateActions.getInvoiceResult(fromDate, toDate, recordsType, offset, memberId)),
    exportInvoiceResultCsv: (fromDate, toDate, recordsType, memberId) => dispatch(invoiceStateActions.exportInvoiceResultCsv(fromDate, toDate, recordsType, memberId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceResult));
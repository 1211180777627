import * as actionTypes from "../constants/actionTypes.js";

const initialState = {
    isLoggedIn: '',
    agreementCancellationRecords: {
        records: [],
        recordsCount: 0
    },
    agreementCancellationExportRecords: {
        csvdata: '',
        filename: '',
        recordsCount: 0
    }
};

export const agreementsCancellationResultRecordsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS:
            return Object.assign({}, ...[state], {agreementCancellationRecords: action.agreementCancellationRecords});
        case actionTypes.FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE:
            return Object.assign({}, ...[state], {});
        case actionTypes.EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS:
            return Object.assign({}, ...[state], {agreementCancellationExportRecords: action.exportRecords});
        case actionTypes.EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE:
            return Object.assign({}, ...[state], {});
        default:
            return state;
    }
};

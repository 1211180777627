import * as actionTypes from "../../constants/digitalsignature/actiontypes.js";

const initialState = {
   isLoggedIn: '',
   digiSignTemplates: []
};

export const digiSignTemplatesReducer = (state = initialState, action) => {
   switch (action.type) {
      case actionTypes.FETCH_DIGISIGN_EMAILTEMPLATES_SUCCESS:
         return Object.assign({}, ...[state], { digiSignTemplates: action.digiSignTemplates });
      case actionTypes.FETCH_DIGISIGN_EMAILTEMPLATES_FAILURE:
         return Object.assign({}, ...[state], {});
      default:
         return state;
   }
};


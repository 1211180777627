import { Login } from './login';
import * as stateActions from "../../actions/actions.js";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
    let data = {       
        isLoggedIn: state.login.isLoggedIn ,
        loginError:state.login.loginError,
        rememberMe:state.login.rememberMe
    };
    return data;
};
const mapDispatchToProps = dispatch => ({
    login: (loginCredentials,rememberMe) => dispatch(stateActions.login(loginCredentials,rememberMe)),
    checkIsLoggedIn: () => dispatch(stateActions.checkIsLoggedIn()),
    updateRememberMe:(rememberMe)=>dispatch(stateActions.updateRememberMe(rememberMe))
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
import React from 'react';
import AgreementCancellationInProcessResultRecord from './inProcessResultRecord';
import * as constants from '../../constants/constants';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import '../../static/css/App.css';

class AgreementCancellationInProcessResultTable extends React.Component {
    render() {

        let componentData;
        let recordElementArray = [];
        if (this.props.records) {
            this
                .props
                .records
                .records
                .map((record, index) => {
                    return recordElementArray.push(<AgreementCancellationInProcessResultRecord key={index} record={record}/>);
                });
        }

        let pagination;

        if (this.props.records) {
            pagination = <nav aria-label="Page navigation example">
                <Pagination defaultPageSize={constants.RECORDS_LIMIT_PER_PAGE} pageSize={constants.RECORDS_LIMIT_PER_PAGE} current={this.props.currentPaginateNumber + 1} defaultCurrent={1} //change it to constant
                    onChange={this.props.handlePagination} total={this.props.records.recordsCount} showTitle={false} hideOnSinglePage={true}/>
            </nav>;

        }

        let recordsTable = (
            <React.Fragment>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Member ID</th>
                                <th>Member Agreement ID</th>
                                <th>Cancellation Effective Date</th>
                                <th>Member Note Status</th>
                                <th>Cancellation Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recordElementArray}
                        </tbody>
                    </table>
                </div>
                {this.props.records && pagination}
            </React.Fragment>
        );

        let emptyRecordsMessage = <h3>{constants.NO_RECORDS_MESSAGE}</h3>;

        if (recordElementArray.length > 0) {
            componentData = recordsTable;
        } else {
            componentData = emptyRecordsMessage;
        }
        return (
            <React.Fragment>
                {!this.props.isLoading && componentData}
                <div className="message-info">
                    {constants.PAGE_INFO_INPROCESS_AGREEMENTS_CANCEL_RECORDS_MESSAGE}
                </div>
            </React.Fragment>
        );
    }
}

export default AgreementCancellationInProcessResultTable;
import InvoiceFileUpload from './fileupload';
import * as loginStateActions from "../../../actions/actions";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as invoiceStateActions from "../../../actions/invoicecreation/actions";

const mapStateToProps = state => {
    let data = {
        isLoggedIn: state.login.isLoggedIn,
        files: state.invoiceFiles.files,
    };
    return data;
};

const mapDispatchToProps = dispatch => ({
    getAllInvoiceFiles: (date) => dispatch(invoiceStateActions.getAllInvoiceFiles(date)),
    logout: () => dispatch(loginStateActions.logout()),
    checkIsLoggedIn: () => dispatch(loginStateActions.checkIsLoggedIn()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceFileUpload));

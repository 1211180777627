import React from 'react';
import FileList from './fileList';
import Loader from 'react-loader-advanced';
import {LOADER} from '../../constants/constants';
import '../../static/css/App.css';
import '../../static/css/index.css';
import '../../static/css/agreementCancellationResults.css';


class FileInput extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            currentDate: this.getCurrentDate(),
            selectedDate: this.getCurrentDate()
        }

        this.getCurrentDate = this.getCurrentDate.bind(this);
        this.getFormattedMonthForCalender = this.getFormattedMonthForCalender.bind(this);
        this.getFormattedDateForCalender = this.getFormattedDateForCalender.bind(this);
        this.get45DaysBeforeDate = this.get45DaysBeforeDate.bind(this);
        this.handleKeyDownDate = this.handleKeyDownDate.bind(this);
        this.handleSelectedDate = this.handleSelectedDate.bind(this);
        this.isFutureDate = this.isFutureDate.bind(this);
        this.isBefore45Days = this.isBefore45Days.bind(this);
        this.returnToCurrent = this.returnToCurrent.bind(this);
    }


    returnToCurrent(){
        let newDate = this.getCurrentDate();
        this.setState({
            selectedDate:newDate
        });
        this.props.handleSelectedDate(newDate);
    }   

    /**
     * 
     * @param {Date} dateObject
     * getting the date 45 before the current 
     */
    get45DaysBeforeDate(dateObject){
        let beforeDays = 45;
        dateObject.setDate(dateObject.getDate()-beforeDays);
        return dateObject.toISOString().substring(0, 10);

    }

    handleKeyDownDate(e){
        e.preventDefault();
    }

    handleSelectedDate(event){
        let newDate = event.target.value;     

        if(newDate === ''){
          newDate = this.getCurrentDate();
        }   
        this.setState({
            selectedDate:newDate
        });

        this.props.handleSelectedDate(newDate);

    }

    isFutureDate(date){

        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var today = new Date(this.state.currentDate);
        var dateToCompare = new Date(date);

        var diffDays = Math.round((dateToCompare.getTime() - today.getTime())/(oneDay));

        if(diffDays>0){
          return true;

        }else{
          return false;
        }
        
      }

    isBefore45Days(date){

        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var today = new Date(this.state.currentDate);
        var dateToCompare = new Date(date);

        var diffDays = Math.round((dateToCompare.getTime() - today.getTime())/(oneDay));

        if(diffDays<-45){
            return true;
        }else{
            return false;
        }

    }


    //getting date formatted as accepted by the date picker
    getCurrentDate(){
        let dateObj = new Date();
        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let date = dateObj.getDate();
        let formattedMonth = this.getFormattedMonthForCalender(month+1);
        let formattedDate = this.getFormattedDateForCalender(date);
        let currentDate = year+'-'+formattedMonth+'-'+formattedDate;

        return currentDate;
    }

    getFormattedMonthForCalender(month){
        if(month<10){
            return '0'+month;
          }
          return month;
    }

    getFormattedDateForCalender(date){     

        if(date<10){
          return '0'+date;
        }      
        return date;
      }

    render() {
        return (
            <div>
                <main className="container">
                    <h1 className="page-heading">Agreement Cancellation File Upload</h1>
                    <div className="file-upload-list white-bg box-shadow">
                    <div className="table-controls clearfix">
                        <div className="datepicker-control">
                        <input
                            onKeyDown = {this.handleKeyDownDate}
                            onChange={this.handleSelectedDate}
                            type="date"
                            id="datepicker"
                            className="form-control"                
                            value = {this.state.selectedDate}
                            max={this.state.currentDate}
                            min = {this.get45DaysBeforeDate(new Date())} 
        
                        />
                        </div>
                        
                        <div className="upload-btn-wrapper">
                        {this.props.selectedFile===""?  <button className="btnUpload" disabled >
                               Upload
                            </button> :  <button className="btnUpload" onClick={(event) => this.props.onUpload(event)}>
                               Upload
                            </button>}
                        </div>
                        <div className="browse-btn-wrapper">
                        {this.props.selectedFile===''?<span></span>:<span className="fileName">{this.props.selectedFile}</span>}
                        
                            <button className="btn" >
                                <i className="fas fa-file-upload"></i>Select file
                            </button>
                            <input type="file" name="myfile" accept='.csv' onClick={this.returnToCurrent}  onChange={(event) => this.props.onSelect(event)}/>

                        </div>

                    </div>
                    <Loader show={this.props.isLoading} message={LOADER} backgroundStyle={{backgroundColor: 'white'}}>
                        <div className="table-responsive">
                            {this.props.files.length === 0 ? <h3>No Files Found!</h3>:<table className="table table-striped">
                            <thead>
                                <tr>
                                <th>File Name</th>
                                <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <FileList files = {this.props.files}/>
                            </tbody>
                            </table>}
                        </div>
                    </Loader>

                    <div className="message-info">
                        *View all the files uploaded/processed on the selected date
                    </div>
                    </div>
                </main>
            </div>        
            )
        }
    }
export default FileInput;
import React from 'react';
import HeaderAC from '../common/headers/headerAC';
import {Redirect} from 'react-router-dom';
import AgreementCancellationSuccessResultTable from './successResultTable';
import AgreementCancellationFailureResultTable from './failureResultTable';
import AgreementCancellationInProcessResultTable from './inProcessResultTable';
import * as constants from '../../constants/constants';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {LOADER} from '../../constants/constants';
import '../../static/css/App.css';
import '../../static/css/index.css';
import '../../static/css/agreementCancellationResults.css';

class ResultsAgreementCancellation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDate: this.getCurrentDate(),
            recordsType: constants.SUCCESS_RECORDS_TYPE,
            currentPaginateNumber: 0,
            isLoading: false,
            isExportCommand: false,
            currentDate: this.getCurrentDate(),
            isExporting: false
        };
    }

    getCurrentDate = () => {

        let dateObj = new Date();

        let year = dateObj.getFullYear();
        let month = dateObj.getMonth();
        let date = dateObj.getDate();

        let formattedMonth = this.getFormattedMonthForCalender(month + 1);

        let formattedDate = this.getFormattedDateForCalender(date);

        let currentDate = year + '-' + formattedMonth + '-' + formattedDate;

        return currentDate;
    }

    componentWillMount() {
        this
            .props
            .checkIsLoggedIn();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({isLoading: false});

        if (this.state.isExportCommand && nextProps.exportCancelRecords && nextProps.exportCancelRecords.recordsCount > 0) {
            let exportCancelData = nextProps.exportCancelRecords;
            this.notifyExportInformation(`Exported ${exportCancelData.recordsCount} records `);
            this.downloadCsvRecords(exportCancelData);
        } else if (this.state.isExportCommand && nextProps.exportCancelRecords && nextProps.exportCancelRecords.recordsCount === 0) {
            this.notifyExportInformation("No Data to export");
            this.setState({isExportCommand: false, isExporting: false});
        }

    }

    downloadCsvRecords = (exportCancelData) => {

        this.link.href = `data:text/csv,${escape(exportCancelData.csvdata)}`;
        this.link.download = exportCancelData.filename;
        this
            .link
            .click();

        this.setState({isExportCommand: false, isExporting: false});

    }

    notifyExportInformation = (message) => {
        toast.info(message, {position: toast.POSITION.TOP_CENTER});
    }

    componentDidMount() {
        this.fetchRecords();
    }

    fetchRecords = () => {
        this.setState({isLoading: true});

        let offset = this.state.currentPaginateNumber * constants.RECORDS_LIMIT_PER_PAGE;
        this
            .props
            .getAgreementCancelRecordsOfDate(this.state.selectedDate, this.state.recordsType, offset);

    }

    getFormattedMonthForCalender = (month) => {

        if (month < 10) {
            return '0' + month;
        }
        return month;
    }

    getFormattedDateForCalender = (date) => {

        if (date < 10) {
            return '0' + date;
        }
        return date;
    }

    componentWillMount() {
        this.setState({isLoading: true});
        this
            .props
            .checkIsLoggedIn();
    }

    handleSelectedDate = (event) => {

        let newDate = event.target.value;

        if (newDate === '') {
            newDate = this.getCurrentDate();
        }

        this.setState({
            selectedDate: newDate,
            currentPaginateNumber: 0
        }, this.fetchRecords);

    };

    handleSelectedRecordsType = (event) => {

        let newRecordType = event.target.value;
        this.setState({
            recordsType: newRecordType,
            currentPaginateNumber: 0
        }, this.fetchRecords);

    }

    handleExport = () => {

        this.setState({isExportCommand: true, isExporting: true});

        if (this.props.agreementCancellationRecords.recordsCount > 0) {
            this
                .props
                .exportAgreementCancelCsv(this.state.selectedDate, this.state.recordsType);
        } else {
            this.notifyExportInformation("No Data to export");
            this.setState({isExportCommand: false, isExporting: false});

        }

    };

    handlePagination = (event) => {

        let pageNumberClicked = event;
        this.setState({
            currentPaginateNumber: pageNumberClicked - 1
        }, this.fetchRecords);
    }

    get45DaysBeforeDate = (dateObject) => {
        let beforeDays = 45;
        dateObject.setDate(dateObject.getDate() - beforeDays);
        return dateObject
            .toISOString()
            .substring(0, 10);
    }

    handleKeyDownDate = (event) => {
        event.preventDefault();
    }

    render() {
        if (this.props.isLoggedIn === '') {
            return <div>{LOADER}</div>

        }
        if (!this.props.isLoggedIn) {
            return <Redirect to='/login'/>;
        }
        return (
            <div className="layout-container">
                <HeaderAC logout={this.props.logout}/>
                <main className="container">
                    {this.state.isLoading && LOADER}
                    <h1 className="page-heading">Agreement Cancellation Results</h1>
                    <div className="file-upload-list white-bg box-shadow">
                        <div className="table-controls clearfix">
                            <div className="records-type-option ">
                                <label>
                                    Select Records Type &nbsp;
                                    <select
                                        className="resultsDD"
                                        value={this.state.recordsType}
                                        onChange={this.handleSelectedRecordsType}
                                        disabled={this.state.isLoading || this.state.isExporting}>
                                        <option value={constants.SUCCESS_RECORDS_TYPE}>Success Records</option>
                                        <option value={constants.FAILURE_RECORDS_TYPE}>Failure Records</option>
                                        <option value={constants.INPROCESS_RECORDS_TYPE}>In Process Records</option>
                                    </select>
                                </label>

                            </div>
                            <div className="datepicker-control">
                                <input
                                    onKeyDown={this.handleKeyDownDate}
                                    onChange={this.handleSelectedDate}
                                    type="date"
                                    id="datepicker"
                                    className="form-control"
                                    value={this.state.selectedDate}
                                    max={this.state.currentDate}
                                    min={this.get45DaysBeforeDate(new Date())}
                                    disabled={this.state.isLoading || this.state.isExporting}/>
                            </div>
                            <a
                                ref={link => this.link = link}
                                style={{
                                display: 'none'
                            }}/>
                            <button
                                className="btn"
                                onClick={this.handleExport}
                                disabled={this.state.isLoading || this.state.isExporting}>
                                <i className="fas fa-file-export"></i>{this.state.isExporting && LOADER}
                                Export as CSV</button>
                        </div>
                        {Number(this.state.recordsType) === constants.SUCCESS_RECORDS_TYPE
                            ? (<AgreementCancellationSuccessResultTable
                                isLoading={this.state.isLoading}
                                selectedDate={this.state.selectedDate}
                                currentDate={this.state.currentDate}
                                records={this.props.agreementCancellationRecords}
                                currentPaginateNumber={this.state.currentPaginateNumber}
                                handlePagination={this.handlePagination}/>)
                            : (Number(this.state.recordsType) === constants.FAILURE_RECORDS_TYPE
                                ? <AgreementCancellationFailureResultTable
                                        isLoading={this.state.isLoading}
                                        selectedDate={this.state.selectedDate}
                                        currentDate={this.state.currentDate}
                                        records={this.props.agreementCancellationRecords}
                                        currentPaginateNumber={this.state.currentPaginateNumber}
                                        handlePagination={this.handlePagination}/>
                                : (Number(this.state.recordsType) === constants.INPROCESS_RECORDS_TYPE
                                    ? <AgreementCancellationInProcessResultTable
                                            isLoading={this.state.isLoading}
                                            selectedDate={this.state.selectedDate}
                                            currentDate={this.state.currentDate}
                                            records={this.props.agreementCancellationRecords}
                                            currentPaginateNumber={this.state.currentPaginateNumber}
                                            handlePagination={this.handlePagination}/>
                                    : ''))}

                    </div>
                </main>
                <ToastContainer autoClose={2000}/>
            </div>
        )
    }
}
export default ResultsAgreementCancellation;
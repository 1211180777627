import * as types from '../constants/actionTypes.js';

export function login(loginCredentials, rememberMe) {
    return {loginCredentials: loginCredentials, rememberMe: rememberMe, type: types.LOGIN};
}

export function loginFailure(error) {
    return {
        type: types.LOGIN_FAILURE,
        loginError: {
            status: true
        }
    };
}

export function loginSuccess(loginResult) {
    return {type: types.LOGIN_SUCCESS, loginResult: loginResult};
}

export function checkIsLoggedIn() {
    return {type: types.CHECK_LOGIN};
}

export function getAllFiles(date) {
    return {date: date, type: types.FETCH_FILES};
}

export function getAllFilesFailure() {
    return {type: types.FETCH_FILES_FAILURE};
}

export function getAllFilesSuccess(files) {
    return {type: types.FETCH_FILES_SUCCESS, files: files};
}

export function storeData(data) {
    return {data: data, type: types.STORE_DATA};
}

export function storeDataFailure() {
    return {type: types.STORE_DATA_FAILURE};
}

export function storeDataSuccess(isProcessed) {
    return {type: types.STORE_DATA_SUCCESS, isProcessed: isProcessed};
}

export function logout() {
    return {type: types.LOGOUT}
}

export function logoutFailure(err) {
    return {
        type: types.LOGOUT_FAILURE,
        error: err.message + " Logout Failed"
    };
}

export function logoutSuccess(isLoggedIn) {
    return {type: types.LOGOUT_SUCCESS, isLoggedIn: isLoggedIn};
}

export function updateRememberMe(rememberMe) {
    return {type: types.UPDATE_REMEMBER_ME, rememberMe: rememberMe}

}

export function updateRememberMeFailure() {
    return {type: types.UPDATE_REMEMBER_ME_FAILURE};
}

export function updateRememberMeSuccess(rememberMe) {
    return {type: types.UPDATE_REMEMBER_ME_SUCCESS, rememberMe: rememberMe};
}

export function getAgreementCancelRecordsOfDate(date, recordsType, offset) {
    return {
        type: types.FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE,
        searchInfo: {
            date: date,
            recordsType: recordsType,
            offset: offset
        }
    };
}

export function getAgreementCancelRecordsOfDateSuccess(records) {
    return {type: types.FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS, agreementCancellationRecords: records};
}

export function getAgreementCancelRecordsOfDateFailure() {
    return {type: types.FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE};
}

export function exportAgreementCancelCsv(date, recordsType) {
    return {
        type: types.EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE,
        searchInfo: {
            date: date,
            recordsType: recordsType
        }
    };
}

export function exportAgreementCancelCsvSuccess(recordsInfo) {
    return {type: types.EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS, exportRecords: recordsInfo};
}

export function exportAgreementCancelCsvFailure() {
    return {type: types.EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE};
}
import * as types from '../../constants/digitalsignature/actiontypes';

export function getAllDigiSignFiles(date) {
    return { date: date, type: types.FETCH_DIGISIGN_FILES };
}

export function getAllDigiSignFilesFailure() {
    return { type: types.FETCH_DIGISIGN_FILES_FAILURE };
}

export function getAllDigiSignFilesSuccess(files) {
    
    return { type: types.FETCH_DIGISIGN_FILES_SUCCESS, files: files };
}

export function getAllDigiSignTemplates() {
    return { type: types.FETCH_DIGISIGN_EMAILTEMPLATES };
}

export function getAllDigiSignTemplatesFailure() {
    return { type: types.FETCH_DIGISIGN_EMAILTEMPLATES_FAILURE };
}

export function getAllDigiSignTemplatesSuccess(templates) {
    return { type: types.FETCH_DIGISIGN_EMAILTEMPLATES_SUCCESS, digiSignTemplates: templates };
}

export function getDigiSignResult(fromDate, toDate, recordsType, offset, memberId, clubId) {
    
    return {
        type: types.FETCH_DIGISIGN_RESULT,
        searchInfo: {
            fromDate: fromDate,
            toDate: toDate,
            recordsType: recordsType,
            offset: offset,
            memberId: memberId,
            clubId: clubId
        }
    };
}

export function getDigiSignResultSuccess(records) {
    
    return { type: types.FETCH_DIGISIGN_RESULT_SUCCESS, digiSignResult: records };
}

export function getDigiSignResultFailure() {
    return { type: types.FETCH_DIGISIGN_RESULT_FAILURE };
}

export function sendDigiSignEmails(records) {
    
    return {
        type: types.SEND_DIGISIGN_EMAILS,
        records: records
    };
}

export function sendDigiSignEmailsSuccess(records) {
    return {
        type: types.SEND_DIGISIGN_EMAILS_SUCCESS
    };
}

export function sendDigiSignEmailsReset() {
    return {
        type: types.SEND_DIGISIGN_EMAILS_RESET
    };
}

export function sendDigiSignEmailsFailure(records) {
    return {
        type: types.SEND_DIGISIGN_EMAILS_FAILURE
    };
}


export function exportDigiSignResultCsvSuccess(recordsInfo) {
    
    return { type: types.EXPORT_DIGISIGN_RESULT_CSV_SUCCESS, exportDigiSignResult: recordsInfo };
}

export function exportDigiSignResultCsvFailure() {
    return { type: types.EXPORT_DIGISIGN_RESULT_CSV_FAILURE };
}

export function exportDigiSignResultCsv(fromDate, toDate, recordsType, memberId,clubId) {
    
    return {
        type: types.EXPORT_DIGISIGN_RESULT_CSV,
        searchInfo: {
            fromDate: fromDate,
            toDate: toDate,
            recordsType: recordsType,
            memberId: memberId,
            clubId:clubId
        }
    };
}
import React from 'react';
import * as commonConstants from '../../../constants/constants';
import { LOADER, REFRESH_IMAGE, SEARCH_IMAGE, RESET_SEARCH_IMAGE, } from '../../../constants/constants';
import digiSignConstants from '../../../constants/digitalsignature/constants'
class DigiSignResultFilterPanel extends React.Component {

    getMinFromDate = () => {
        return this.get45DaysBeforeDate(new Date());
    }

    getMaxFromDate = () => {
        return this.props.selectedToDate;
    }

    getMinToDate = () => {
        return this.props.selectedFromDate;
    }

    getMaxToDate = () => {
        return this.props.currentDate;
    }

    handleKeyDownDate = (event) => {
        event.preventDefault();
    }

    get45DaysBeforeDate = (dateObject) => {
        let beforeDays = 45;
        dateObject.setDate(dateObject.getDate() - beforeDays);
        return dateObject
            .toISOString()
            .substring(0, 10);
    }

    render() {
        return (
            <React.Fragment>
                <div className="filter-panel digi-sign">
                    <div className="table-controls clearfix">
                        <div className="records-type-option ">
                            <label>
                                Select Records Type &nbsp;
                                <select
                                    className="resultsDD"
                                    value={this.props.recordsType}
                                    onChange={this.props.handleSelectedRecordsType}
                                    disabled={this.props.isLoading || this.props.isExporting}>
                                    <option value={commonConstants.SUCCESS_RECORDS_TYPE}>Success Records</option>
                                    <option value={commonConstants.FAILURE_RECORDS_TYPE}>Failed Records</option>
                                    <option value={commonConstants.INPROCESS_RECORDS_TYPE}>In Process Records</option>
                                </select>
                            </label>

                        </div>
                        <div className="datepicker-control">
                            <div className="datepicker-control-from">
                                <label >Last Email From date:</label>
                                <input
                                    onKeyDown={this.handleKeyDownDate}
                                    onChange={this.props.handleSelectedDate}
                                    type="date"
                                    id="datepicker-from"
                                    name="fromDate"
                                    className="form-control"
                                    value={this.props.selectedFromDate}
                                    max={this.getMaxFromDate()}
                                    min={this.getMinFromDate()}
                                    disabled={this.props.isLoading || this.props.isExporting} />
                            </div>

                            <div className="datepicker-control-to">
                                <label >Last Email To date:</label>
                                <input
                                    onKeyDown={this.handleKeyDownDate}
                                    onChange={this.props.handleSelectedDate}
                                    type="date"
                                    id="datepicker-to"
                                    name="toDate"
                                    className="form-control"
                                    value={this.props.selectedToDate}
                                    max={this.getMaxToDate()}
                                    min={this.getMinToDate()}
                                    disabled={this.props.isLoading || this.props.isExporting} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="table-controls clearfix">
                    <div className="search-member-textbox">
                            <input
                                className="form-control search-member-input"
                                type="text"
                                name="memberId"
                                placeholder="Member ID"
                                value={this.props.memberId}
                                onChange={this.props.handleMemberSearchChange}
                                disabled={!this.props.isMemberSearchValid} />
                            <div className="ds-member-search-buttons">
                                <div className="reset-member-button">
                                    <a onClick={this.props.handleMemberReset}>{RESET_SEARCH_IMAGE}</a>
                                </div>
                            </div>
                        </div>
                        <div className="search-clubid-textbox">
                            <input
                                className="form-control search-clubid-input"
                                type="text"
                                name="clubId"
                                placeholder="Club ID"
                                value={this.props.clubId}
                                onChange={this.props.handleClubIdSearchChange}
                                disabled={!this.props.isClubIdSearchValid} />
                            <div className="ds-clubid-search-buttons">
                                <div className="reset-clubid-button">
                                    <a onClick={this.props.handleClubIdReset}>{RESET_SEARCH_IMAGE}</a>
                                </div>
                            </div>
                            
                        </div>
                        <div className="search-button-box">
                            <button 
                                className=" search-button-ds"
                                onClick={this.props.handleSearch}>
                                Search 
                            </button>
                        </div>
                        <div >
                            {this.props.currentDate === this.props.selectedFromDate || this.props.currentDate === this.props.selectedToDate
                                ? <div className="refresh-button">
                                    <a
                                        className="refresh-anchor"
                                        title="Refresh records"
                                        onClick={this.props.handleRefresh}>{REFRESH_IMAGE}</a>
                                </div>
                                : ''
                            }
                        </div>
                        <button
                            className="btn floatR"
                            onClick={this.props.handleExport}
                            disabled={this.props.isLoading || this.props.isExporting || this.props.isSending}>
                            <i className="fas fa-file-export"></i>{this.props.isExporting && LOADER}
                            Export as CSV</button>
                        <button
                            className="btn marR15 floatR"
                            onClick={this.props.handleSend}
                            disabled={this.props.isLoading || this.props.isExporting || this.props.isSending || this.props.checkedRecords.length == 0}>
                            <i className="fas fa-file-export"></i>{this.props.isSending && LOADER}
                            Send Emails</button>
                        
                    </div>
                    
                    <div className="width-100">
                        <div className="message-info-imp">
                            {digiSignConstants.RESULT_PAGE_MESSAGE.DIGISIGN_EMAILSENT_INFO}
                        </div>

                    </div>

                </div>
            </React.Fragment>

        );
    }
}

export default DigiSignResultFilterPanel;
let constants = () => {

    const UPLOAD_PAGE_MESSAGE = '*View all the files uploaded/processed on the selected date';

    const RESULT_PAGE_MESSAGE = {
        INVOICE_SUCCESS_INFO: '* View all the successful invoices created on the selected date',
        INVOICE_FAILURE_INFO: '* View all the failed invoices on the selected date',
        INVOICE_INPROCESS_INFO: '* View all the In Process invoices on the selected date'
    };

    const REGEX_FILENAME = (/Invoice_[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])_(2[0-3]|[01][0-9])-[0-5][0-9].csv/);

    const FILENAME_INVALID_MESSAGE = 'Invalid filename. Please rename as Invoice_YYYY-MM-DD_HH-MM';

    const API_URL = {
        FETCH_FILES: '/invoicecreation/filesofDate',
        UPLOAD_FILE: '/invoicecreation/uploadCsv',
        FETCH_INVOICE_RESULT: '/invoicecreation/records',
        EXPORT_INVOICE_RESULT: '/invoicecreation/export',
        CHECK_DUPLICATE_FILE_NAME: '/invoicecreation/isFilenameDuplicate'
    };

    const FILENAME_DUPLICATE_MESSAGE = 'Sorry! A file with the same name already exists in the system';


    return {
        RESULT_PAGE_MESSAGE: RESULT_PAGE_MESSAGE,
        UPLOAD_PAGE_MESSAGE: UPLOAD_PAGE_MESSAGE,
        REGEX_FILENAME: REGEX_FILENAME,
        FILENAME_INVALID_MESSAGE: FILENAME_INVALID_MESSAGE,
        FILENAME_DUPLICATE_MESSAGE: FILENAME_DUPLICATE_MESSAGE,
        API_URL: API_URL
    }
}

module.exports = constants();

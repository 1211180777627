let constants = () => {

    const UPLOAD_PAGE_MESSAGE = '*View all the files uploaded/processed on the selected date';

    const RESULT_PAGE_MESSAGE = {
        CREDITS_SUCCESS_INFO: '* View all the successful credits processed on the selected date',
        CREDITS_FAILURE_INFO: '* View all the failed credits on the selected date',
        CREDITS_INPROCESS_INFO: '* View all the In Process credits on the selected date'
    };

    const REGEX_FILENAME = (/Credits_[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])_(2[0-3]|[01][0-9])-[0-5][0-9].csv/);

    const FILENAME_INVALID_MESSAGE = 'Error! File name format should be Credits_YYYY-MM-DD_HH-MM.csv';
    const FILENAME_DUPLICATE_MESSAGE = ' Sorry! A file with the same name already exists in the system';

    const CREDIT_POSTING_URL = {
        FETCH_CREDIT_RESULT: '/creditposting/records',
        EXPORT_CREDIT_RESULT: '/creditposting/export'
    }

    return {
        RESULT_PAGE_MESSAGE: RESULT_PAGE_MESSAGE,
        UPLOAD_PAGE_MESSAGE: UPLOAD_PAGE_MESSAGE,
        REGEX_FILENAME: REGEX_FILENAME,
        FILENAME_INVALID_MESSAGE: FILENAME_INVALID_MESSAGE,
        CREDIT_POSTING_URL: CREDIT_POSTING_URL,
        FILENAME_DUPLICATE_MESSAGE: FILENAME_DUPLICATE_MESSAGE
    }
}

module.exports = constants();

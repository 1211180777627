import * as actionTypes from "../../constants/digitalsignature/actiontypes.js";
import digiSignConstants from '../../constants/digitalsignature/constants';

const initialState = {
   isLoggedIn: '',
   digiSignResultRecords: {
      records: [],
      recordsCount: 0
   },
   digiSignResultExportRecords: {
      csvdata: '',
      filename: '',
      recordsCount: 0,
      isExportSuccess: false,
      isExportRecord: false
   },
   emailSendStatus: digiSignConstants.EMAIL_SEND_STATUS.NONE
};

export const digiSignResultReducer = (state = initialState, action) => {
   // console.log('action type ',action.type);
   switch (action.type) {
      case actionTypes.FETCH_DIGISIGN_RESULT_SUCCESS:
         // action.digiSignResult.records.map(record => {
         //    record["IsChecked"] = false;
         //    return record;
         // })
         return Object.assign({}, ...[state], {
            digiSignResultRecords: action.digiSignResult
         });
      case actionTypes.FETCH_DIGISIGN_RESULT_FAILURE:
         return Object.assign({}, ...[state], {});
      case actionTypes.SEND_DIGISIGN_EMAILS_RESET:
         return Object.assign({}, ...[state], {
            emailSendStatus: digiSignConstants.EMAIL_SEND_STATUS.NONE
         });
      case actionTypes.SEND_DIGISIGN_EMAILS_SUCCESS:
         return Object.assign({}, ...[state], {
            emailSendStatus: digiSignConstants.EMAIL_SEND_STATUS.SUCCESS
         });
      case actionTypes.SEND_DIGISIGN_EMAILS_FAILURE:
         return Object.assign({}, ...[state], {
            emailSendStatus: digiSignConstants.EMAIL_SEND_STATUS.FAILED
         });
      case actionTypes.EXPORT_DIGISIGN_RESULT_CSV_SUCCESS:
         return Object.assign({}, ...[state], { digiSignResultExportRecords: action.exportDigiSignResult });
      case actionTypes.EXPORT_DIGISIGN_RESULT_CSV_FAILURE:
         return Object.assign({}, ...[state], {});
      default:
         return state;
   }
};


import { USERNAME_EMPTY_ERROR, PASSWORD_EMPTY_ERROR, KEY } from "../constants/constants.js";

import * as path from "../constants/constants.js";

import httpRequests from './httpRequestHandler';

const encryptor = require('simple-encryptor')(KEY);

export const login = (loginCredentials) => {

    let data = loginCredentials.loginCredentials;
    let rememberMe = loginCredentials.rememberMe;
    let user = {
        'username': data.username,
        'password': data.password
    };

    let url = process.env.REACT_APP_API_URL + '/admin/login';

    let loginErrorValue = {
        status: false,
        usernameError: false,
        passwordError: false,
        usernameErrorMessage: '',
        passwordErrorMessage: '',
        loginVerifyError: false
    };

    if (user.username === '') {
        loginErrorValue.status = true;
        loginErrorValue.usernameError = true;
        loginErrorValue.usernameErrorMessage = USERNAME_EMPTY_ERROR;
    };
    if (user.password === '') {
        loginErrorValue.status = true;
        loginErrorValue.passwordError = true;
        loginErrorValue.passwordErrorMessage = PASSWORD_EMPTY_ERROR;
    };

    if (loginErrorValue.status) {

        let loginResult = {
            isLoggedIn: false,
            loginError: loginErrorValue
        };

        return loginResult;
    }

    return withTimeout(10000, fetch(url, {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json'
        }
    })).then((responsePromise) => {

        return responsePromise.json();
    }).then((response) => {

        //updating token in localStorage

        if (response.admin) {
            if (rememberMe) {
                localStorage.setItem('token', response.admin.token);
                let encryptedUsername = encryptor.encrypt(user.username);
                localStorage.setItem('username', encryptedUsername);
                let encryptedPassword = encryptor.encrypt(user.password);
                localStorage.setItem('password', encryptedPassword);
            } else {
                localStorage.setItem('token', response.admin.token);
                localStorage.removeItem('username');
                localStorage.removeItem('password');
            }

            let loginResult = {
                isLoggedIn: true,
                loginError: loginErrorValue
            };

            return loginResult;

        } else {

            loginErrorValue.status = true;

            // Response has been received from the server

            if (loginErrorValue.status) {

                let loginErrorResult = {
                    isLoggedIn: false,
                    loginError: loginErrorValue
                };

                return loginErrorResult
            }

        }

    }).catch((error) => {
        console.log(error);
        let errorString = error + '';
        if (errorString.includes('timeout') || errorString.includes('Failed to fetch')) {
            console.log('not reachable');
            return window
                .location
                .replace('/server-not-reachable'); //unreachable server
        } else {

            loginErrorValue.status = true;

            let loginResult = {
                isLoggedIn: false,
                loginError: loginErrorValue
            };

            return loginResult;

        }

    });
}

export const checkTokenValidity = () => {

    let url = process.env.REACT_APP_API_URL + '/admin/';
    return httpRequests
        .get(url)
        .then((response) => {
            if (!response.admin) {
                return false;
            }
            return true;
        })
        .catch(err => {
            return false;
        });

}

const withTimeout = (msecs, promise) => {
    const timeout = new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('timeout'));
        }, msecs);
    });
    return Promise.race([timeout, promise]);
}
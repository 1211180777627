import * as actionTypes from "../../constants/invoicecreation/actiontypes.js";

const initialState = {
   isLoggedIn: '',
   invoiceResultRecords: {
      records: [],
      recordsCount: 0
   },
   invoiceResultExportRecords: {
      csvdata: '',
      filename: '',
      recordsCount: 0,
      isExportSuccess: false,
      isExportRecord: false
   }
};

export const invoiceResultReducer = (state = initialState, action) => {
   switch (action.type) {
      case actionTypes.FETCH_INVOICE_RESULT_SUCCESS:
         return Object.assign({}, ...[state], { invoiceResultRecords: action.invoiceResult });
      case actionTypes.FETCH_INVOICE_RESULT_FAILURE:
         return Object.assign({}, ...[state], {});
      case actionTypes.EXPORT_INVOICE_RESULT_CSV_SUCCESS:
         return Object.assign({}, ...[state], { invoiceResultExportRecords: action.exportInvoiceResult });
      case actionTypes.EXPORT_INVOICE_RESULT_CSV_FAILURE:
         return Object.assign({}, ...[state], {});
      default:
         return state;
   }
};


import * as actionTypes from "../../constants/creditposting/actiontypes.js";

const initialState = {
    files:[],
};

export const getCreditFilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CREDIT_FILES_SUCCESS:
           return  Object.assign({},...[state],{files:action.files});
        default:
           return state;
    }
};


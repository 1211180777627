import React, { Component } from 'react';
import './static/css/App.css';
import { Route, Switch } from "react-router-dom";
import FileUploadContainer from './components/fileUploadAgreementCancellation/fileUploadContainer';
import LoginContainer from "./components/login/loginContainer.js";
import { Redirect } from 'react-router-dom';
import ResultsAgreementCancellationContainer from './components/ResultsAgreementCancellation/resultsContainer';
import HomeContainer from "./components/home/homeContainer";
import My404Component from './components/common/error/my404Component';
import CreditFileUploadContainer from './components/creditposting/fileupload/uploadcontainer';
import CreditResultContainer from './components/creditposting/results/resultscontainer';
import ServerNotReachableComponent from './components/common/error/servernotreachable';
import ServerErrorComponent from './components/common/error/servererror';
import InvoiceFileUploadContainer from './components/invoicecreation/fileupload/uploadcontainer';
import InvoiceResultContainer from './components/invoicecreation/results/resultscontainer';
import DigitalSignatureResultContainer from './components/digitalsignature/results/resultscontainer';
import DigiSignTemplateContainer from './components/digitalsignature/emailTemplates/templatesContainer';
import DigiSignFilesContainer from './components/digitalsignature/files/filescontainer';

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();

class App extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/login" component={LoginContainer} />
                    <Route exact path="/home" component={HomeContainer} />
                    <Route
                        exact
                        path="/home/agreementCancellation/fileUpload"
                        component={FileUploadContainer} />
                    <Route
                        exact
                        path="/home/agreementCancellation/results"
                        component={ResultsAgreementCancellationContainer} />
                    <Route
                        exact
                        path="/home/creditPosting/fileUpload"
                        component={CreditFileUploadContainer} />
                    <Route
                        exact
                        path="/home/creditPosting/results"
                        component={CreditResultContainer} />
                    <Route
                        exact
                        path="/home/invoiceCreation/fileUpload"
                        component={InvoiceFileUploadContainer} />
                    <Route
                        exact
                        path="/home/invoiceCreation/results"
                        component={InvoiceResultContainer} />
                    <Route
                        exact
                        path="/home/digitalsignature/results"
                        component={DigitalSignatureResultContainer} />
                    <Route
                        exact
                        path="/home/digitalsignature/emailtemplates"
                        component={DigiSignTemplateContainer} />
                    <Route
                        exact
                        path="/home/digitalsignature/files"
                        component={DigiSignFilesContainer} />

                    <Route
                        exact
                        path="/server-not-reachable"
                        component={ServerNotReachableComponent} />
                    <Route
                        exact
                        path="/server-error"
                        component={ServerErrorComponent} />
                    <Route exact path='/404' component={My404Component} />
                    <Redirect exact from="/" to="login" />
                    <Redirect from='*' to='/404' />
                </Switch>
            </div>
        );
    }
}

export default App;

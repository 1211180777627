import React from 'react';
import Header from '.././headers/header';
import {SERVER_UNREACHABLE_MESSAGE_TITLE, SERVER_UNREACHABLE_MESSAGE_HELPER} from '../../../constants/constants';
import {Link} from "react-router-dom";

class ServerNotReachableComponent extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="layout-container">
                    <Header/>
                    <main className="container">
                        <div className="login-form error-404 box-shadow text-center">
                            <div className="error-404-message-title">{SERVER_UNREACHABLE_MESSAGE_TITLE}</div>
                            <h3>{SERVER_UNREACHABLE_MESSAGE_HELPER}</h3>

                        </div>
                    </main>
                </div>
            </React.Fragment>
        )
    }
}
export default ServerNotReachableComponent;
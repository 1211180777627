import React from 'react';
import * as commonConstants from '../../../../constants/constants';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import moment from 'moment';

class InvoiceFailedResultTable extends React.Component {

    render() {
        let componentData;
        let recordElementArray = [];
        if (this.props.records) {
            let successRecord;
            this
                .props
                .records
                .records
                .map((record, index) => {
                    successRecord = <tr key={index}>
                        <td title={'Processed on - ' + moment(record.createdAt).format('MMMM DD, YYYY')}>{record.Name}</td>
                        <td>{record.MemberId}</td>
                        <td>{record.invoicecreationmosorequestId === null
                                ? 'N/A'
                                : record.invoicecreationmosorequestId}</td>
                        
                        <td>{record.Amount=== null
                                ? 'N/A'
                                : record.Amount}</td>
                        <td>{record.ErrorMessage}</td>
                        <td>{record.ErrorCode}</td>

                    </tr>;

                    return recordElementArray.push(successRecord);
                });
        }

        let pagination;

        if (this.props.records) {
            pagination = <nav aria-label="Page navigation example">
                <Pagination defaultPageSize={commonConstants.CREDIT_RECORDS_LIMIT_PER_PAGE} pageSize={commonConstants.CREDIT_RECORDS_LIMIT_PER_PAGE} current={this.props.currentPaginateNumber + 1} defaultCurrent={1} //change it to constant
                    onChange={this.props.handlePagination} total={this.props.records.recordsCount} showTitle={false} hideOnSinglePage={true}/>
            </nav>;

        }

        let recordsTable = (
            <React.Fragment>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Member ID</th>
                                <th>MOSO Request Id</th>
                                <th>Amount</th>
                                <th>Error Message</th>
                                <th>Error Code</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {recordElementArray}
                        </tbody>
                    </table>
                </div>
                {this.props.records && pagination}
            </React.Fragment>
        );

        let emptyRecordsMessage = <h3>{commonConstants.NO_RECORDS_MESSAGE}</h3>;
        let emptySearchRecordsMessage = <h3>{commonConstants.NO_RECORDS_SEARCH_CRITERIA_MESSAGE}</h3>;

        if (recordElementArray.length > 0) {
            componentData = recordsTable;
        } else if (this.props.isSearchCriteria && this.props.memberId.length >= 0) {
            componentData = emptySearchRecordsMessage;
        } else {
            componentData = emptyRecordsMessage;
        }
        return (
            <React.Fragment>

                {!this.props.isLoading && componentData}

            </React.Fragment>

        );
    }
}

export default InvoiceFailedResultTable;
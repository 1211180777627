import React from 'react';
import * as commonConstants from '../../../../constants/constants';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import moment from 'moment';
import momenttimezeone from 'moment-timezone';

class ErrorResultTable extends React.Component {

    render() {
        let componentData;
        let recordElementArray = [];
        if (this.props.records) {
            let successRecord;
            this
                .props
                .records
                .records
                .map((record, index) => {
                    successRecord = <tr key={index}>
                        <td>{record.CsvFileName}</td>
                        <td>{record.MemberId}</td>
                        <td>{record.MemberAgreementId}</td>
                        <td>{record.FirstName+' '+record.LastName}</td>
                        <td>{record.EmailId}</td>
                        <td>{record.ClubId}</td> 
                        <td>{momenttimezeone.unix(record.CreatedAt).tz('America/New_York').format('MMMM DD, YYYY')}</td>
                        <td>{record.ErrorMessage}</td>
                        <td>{record.ErrorType}</td>
                    </tr>;

                    return recordElementArray.push(successRecord);
                });
        }

        let pagination;

        if (this.props.records) {
            pagination = <nav aria-label="Page navigation example">
                <Pagination defaultPageSize={commonConstants.DIGISIGN_RECORDS_LIMIT_PER_PAGE} pageSize={commonConstants.DIGISIGN_RECORDS_LIMIT_PER_PAGE} current={this.props.currentPaginateNumber + 1} defaultCurrent={1} //change it to constant
                    onChange={this.props.handlePagination} total={this.props.records.recordsCount} showTitle={false} hideOnSinglePage={true} />
            </nav>;

        }

        let recordsTable = (
            <React.Fragment>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Member ID</th>
                                <th>MemberAgreement ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Club Id</th> 
                                <th>Error Date</th>
                                <th>Error Message</th>
                                <th>Error Source</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recordElementArray}
                        </tbody>
                    </table>
                </div>
                {this.props.records && pagination}
            </React.Fragment>
        );

        let emptyRecordsMessage = <h3>{commonConstants.NO_RECORDS_MESSAGE}</h3>;

        let emptySearchRecordsMessage = <h3>{commonConstants.NO_RECORDS_SEARCH_CRITERIA_MESSAGE}</h3>;

        if (recordElementArray.length > 0) {
            componentData = recordsTable;
        } else if (this.props.isSearchCriteria && this.props.memberId.length >= 0) {
            componentData = emptySearchRecordsMessage;
        } else {
            componentData = emptyRecordsMessage;
        }
        return (
            <React.Fragment>

                {!this.props.isLoading && componentData}

            </React.Fragment>

        );
    }
}

export default ErrorResultTable;
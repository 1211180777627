export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const CHECK_LOGIN = 'CHECK_LOGIN';

export const LOGIN_ERROR_MESSAGE_UPDATE = 'LOGIN_ERROR_MESSAGE_UPDATE';

export const FETCH_RESULTS_AGREEMENTCANCELLATION = 'FETCH_RESULTS_AGREEMENTCANCELLATION';
export const FETCH_RESULTS_AGREEMENTCANCELLATION_SUCCESS = 'FETCH_RESULTS_AGREEMENTCANCELLATION_SUCCESS';
export const FETCH_RESULTS_AGREEMENTCANCELLATION_FAILURE = 'FETCH_RESULTS_AGREEMENTCANCELLATION_FAILURE';

export const FETCH_FILES = 'FETCH_FILES';
export const FETCH_FILES_SUCCESS = 'FETCH_FILES_SUCCESS';
export const FETCH_FILES_FAILURE = 'FETCH_FILES_FAILURE';

export const STORE_DATA = 'STORE_DATA';
export const STORE_DATA_SUCCESS = 'STORE_DATA_SUCCESS';
export const STORE_DATA_FAILURE = 'STORE_DATA_FAILURE';

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const UPDATE_REMEMBER_ME = 'UPDATE_REMEMBER_ME';
export const UPDATE_REMEMBER_ME_SUCCESS = 'UPDATE_REMEMBER_ME_SUCCESS';
export const UPDATE_REMEMBER_ME_FAILURE = 'UPDATE_REMEMBER_ME_FAILURE';

export const FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE = 'FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE';
export const FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS = 'FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS';
export const FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE = 'FETCH_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE';

export const EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE = 'EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE';
export const EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS = 'EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_SUCCESS';
export const EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE = 'EXPORT_AGREEMENTS_CANCELLATION_RECORDS_DATE_FAILURE';

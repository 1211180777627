import React from 'react';
import * as commonConstants from '../../../../constants/constants';
import InvoiceSuccessResultTable from './successresulttable';
import InvoiceFailedResultTable from './failedresulttable';
import InvoiceInprocessResultTable from './inprocessresulttable';

class InvoiceResultTable extends React.Component {

    render() {
        return (
            <React.Fragment>
                {Number(this.props.recordsType) === commonConstants.SUCCESS_RECORDS_TYPE
                    ? (<InvoiceSuccessResultTable
                        isLoading={this.props.isLoading}
                        selectedDate={this.props.selectedDate}
                        currentDate={this.props.currentDate}
                        records={this.props.records}
                        currentPaginateNumber={this.props.currentPaginateNumber}
                        handlePagination={this.props.handlePagination}
                        isSearchCriteria={this.props.isSearchCriteria}
                        memberId={this.props.memberId}/>)
                    : (Number(this.props.recordsType) === commonConstants.FAILURE_RECORDS_TYPE
                        ? <InvoiceFailedResultTable
                                isLoading={this.props.isLoading}
                                selectedDate={this.props.selectedDate}
                                currentDate={this.props.currentDate}
                                records={this.props.records}
                                currentPaginateNumber={this.props.currentPaginateNumber}
                                handlePagination={this.props.handlePagination}
                                isSearchCriteria={this.props.isSearchCriteria}
                                memberId={this.props.memberId}/>
                        : (Number(this.props.recordsType) === commonConstants.INPROCESS_RECORDS_TYPE
                            ? <InvoiceInprocessResultTable
                                    isLoading={this.props.isLoading}
                                    selectedDate={this.props.selectedDate}
                                    currentDate={this.props.currentDate}
                                    records={this.props.records}
                                    currentPaginateNumber={this.props.currentPaginateNumber}
                                    handlePagination={this.props.handlePagination}
                                    isSearchCriteria={this.props.isSearchCriteria}
                                    memberId={this.props.memberId}/>
                            : ''))}

            </React.Fragment>

        );
    }
}

export default InvoiceResultTable;